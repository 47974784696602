import React, { Component } from 'react';
import { PageActions, Stack, Layout, FormLayout, TextField, Card,Button,RadioButton,ButtonGroup, List, Banner, TextStyle, Tag, Link, Form } from '@shopify/polaris';
import { DeleteMinor, LockMinor } from '@shopify/polaris-icons';
import PageTitleBar from '../Common/TitleBar';
import ToastMessage from '../Common/ToastMessage';
import SkeletonPageComp from './SkeletonPageComp';
import Common from '../Helpers/Common';
import axios from '../Axios';
import LoadingOverlay from './LoadingOverlay';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

class CreateCartLevelDiscount extends Component{
  constructor(props){
    super(props);
    this.state = {
      title: "",
      state: "published",
      customer_group: "has_tag",
      discount_type: "percentage",
      calculation_type: "cart_subtotal",
      allocation_method: "post_discount",
      discounts: [
        {
          value: "",
          discount_value : ""
        }
      ],
      tags: [],
      tags_excluded: [],
      errorList: [],
      showToast: false,
      toastMsg: "",
      toastError: false,
      temp__customer_tag: "",
      page_loaded: typeof this.props.match.params.id !== "undefined"? false : true,
      save_btn_loading: false,
      message: "Additional Discount {{discount}}"
    };
    axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://"+this.props.shop;
  }

  componentDidMount() {
    if(typeof this.props.match.params.id !== "undefined"){
      this.getForEdit();
    }
  }

  getForEdit(){
    axios.get('/app/cart-discount?shop='+this.props.shop+'&id=' + this.props.match.params.id)
    .then(response => {
      if(response.data){
        let data = response.data;
        this.setState({
          title: data.title,
          message: data.message,
          state: data.state,
          customer_group: data.customer_group,
          discount_type: data.discount_type,
          calculation_type: data.calculation_type,
          allocation_method: data.allocation_method,
          discounts: data.discounts,
          tags: data.tags,
          tags_excluded: data.tags_excluded,
          page_loaded: true
        });
      }
      else{
        this.toast("Could not load data. Try again", true);
      }
    })
    .catch(error => {
      this.setState({page_loaded: true});
    });
  }

  scrollToTop(){
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  build_discout_range(){
    let html = this.state.discounts.map((item,vIndex) => {
        return <div key={('dr'+vIndex)}>
            <Stack alignment="center" distribution="leading">
                <Stack.Item fill>
                    <Stack spacing="extraTight">
                        <TextField
                            autoComplete={false}
                            labelHidden
                            type="number"
                            label="Discount Value"
                            prefix={this.state.calculation_type === "cart_quantity"?"Min Qty":"Min Amount"}
                            value={getValue(item.value)}
                            onChange={(v)=>{
                              let discounts = this.state.discounts;
                              discounts[vIndex]["value"] = v;
                              this.setState({discounts});
                            }}
                        />
                        <TextField
                            autoComplete={false}
                            labelHidden
                            type="number"
                            label="Discount"
                            prefix="Discount"
                            suffix={
                              this.state.discount_type === "percentage"?"%":"USD"
                            }
                            value={getValue(item.discount_value)}
                            onChange={(v)=>{
                                let discounts = this.state.discounts;
                                discounts[vIndex]["discount_value"] = v;
                                this.setState({discounts});
                            }}
                        />
                        {
                          this.state.discounts.length > 0 ?
                          <Button
                            disabled={this.state.discounts.length === 1}
                            onClick={() => {
                              let discounts = this.state.discounts;
                              discounts.splice(vIndex,1);
                              this.setState({discounts});
                            }}
                            icon={DeleteMinor}
                          >
                          </Button>
                          : <div></div>
                        }
                    </Stack>
                </Stack.Item>
            </Stack>
        </div>;
    });
    let ui = <Stack vertical>
        <Stack.Item>
            <FormLayout>{html}</FormLayout>
        </Stack.Item>
        <Stack.Item>
            <ButtonGroup>
                <Button onClick={() => {
                    let discounts = this.state.discounts;
                    discounts.push({
                        value: "",
                        discount_value: ""
                    });
                    this.setState({discounts})
                }}>
                    {this.state.discounts.length >= 1 ? 'Add more' : 'Add'}
                </Button>
                {
                    this.state.discounts.length > 10 &&
                    <Button
                        onClick={() => {
                            let discounts = this.state.discounts;
                            discounts = discounts.filter(x=> x.value !== "");
                            discounts.sort(function(a,b){
                              return Number(a.value) - Number(b.value)
                            });
                            this.setState({discounts});
                        }}
                    >Sort by [0-9]</Button>
                }
            </ButtonGroup>
        </Stack.Item>
    </Stack>;
    return ui;
  }

  doValidation(){
    var errors = [], isValid = true;
    var discounts = [];
    try {
      discounts = this.state.discounts.filter(x => x.value !== "" && x.discount_value !== "" );
    } catch (e) { }
    if (this.state.customer_group === "has_tag") {
      if (this.state.tags.length === 0) {
        isValid=false;
        errors.push("Add at least 1 tag for tag based customers");
      }
    }
    if (discounts.length > 0) {
      let error; let less_than_zero_price = false; let percent_greater100 = false;
      let discount_type = this.state.discount_type;
      let total = discounts.length;
      discounts.forEach(function (item, idx) {
        if ((item.value === '' || item.discount_value === '') && total === 1) {
          error = true;
        }
        else if (Number(item.discount_value) < 0) {
          less_than_zero_price = true;
        }
        if (discount_type === 'percentage' && Number(item.discount_value) > 100) {
          error = true;
          percent_greater100 = true;
        }
      });
      if (less_than_zero_price) {
        errors.push("Discount value must be greater than zero");isValid=false;
      }
      if (percent_greater100) {
        errors.push("Percentage value can not exceed 100");isValid=false;
      }
      if (error) {
        errors.push("Discount Pricing value can not be blank");isValid=false;
      }
    }
    else{
      errors.push("Discount Pricing value can not be blank");isValid=false;
    }
    return {
      isValid: isValid,
      errors: errors
    };
  }

  save_rule(){
    if(Common.getPlanID() !== 3){
      this.toast("upgrade your plan to continue using volume pricing", true);
    }
    else {
      var validation = this.doValidation();
      if(validation.isValid){
        this.setState({ errorList : [], save_btn_loading: true});
        var discounts = [];
        try {
          discounts = this.state.discounts.filter(x => x.value !== "" && x.discount_value !== "");
        } catch (e) { }
        try {
          discounts = JSON.parse(JSON.stringify(discounts)).map(x => {
            try {
              x.value = Number(x.value);
            } catch (e) { }
            try {
              x.discount_value = Number(x.discount_value);
            } catch (e) { }
            return x;
          });
        } catch (e) { }
        try {
          discounts.sort(function(a,b){
            return Number(a.value) - Number(b.value);
          });
        } catch (e) { }
        var data = {
          shop: this.props.shop,
          title: this.state.title,
          message: this.state.message || "Additional Discount {{discount}}",
          state: this.state.state,
          customer_group: this.state.customer_group,
          discount_type: this.state.discount_type,
          calculation_type: this.state.calculation_type,
          allocation_method: this.state.allocation_method,
          discounts: discounts,
          tags: this.state.tags,
          tags_excluded: (this.state.customer_group !== "all" && this.state.customer_group !== "has_account")?[]:this.state.tags_excluded
        };
        if(this.props.show === "edit" && this.props.match.params.id){
          var id = this.props.match.params.id;
          axios.put('/app/cart-discount?shop='+this.props.shop+'&id='+id, data)
          .then(response => {
              if (response.data.status === 1) {
                  this.toast(response.data.message);
                  this.props.history.push(BUILD_VERSION_EXTENSION+'/cart-discounts/list');
              } else {
                  this.setState({ save_btn_loading: false });
                  this.toast(response.data.message, true);
                  return false;
              }
          })
          .catch(error => {
            this.setState({ save_btn_loading: false });
            this.toast("Server error", true);
          });
        }
        else{
          axios.post('/app/cart-discount', data)
          .then(response => {
              if (response.data.status === 1) {
                  this.toast(response.data.message);
                  this.props.history.push(BUILD_VERSION_EXTENSION+'/cart-discounts/list');
              } else {
                  this.setState({ save_btn_loading: false });
                  this.toast(response.data.message, true);
                  return false;
              }
          })
          .catch(error => {
            this.setState({ save_btn_loading: false });
            this.toast("Server error", true);
          });
        }
      }
      else{
        let errorList = validation.errors || [];
        let msg = `There ${errorList.length > 1?"are":"is"} ${errorList.length } ${errorList.length > 1?"errors":"error"}. Fix them before saving the rule`;
        this.toast(msg, true);
        this.setState({ errorList : errorList, save_btn_loading: false });
        this.scrollToTop();
      }
    }
  }

  toast(msg,error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error
    });
  }

  render_tags(tags, excluded){
    return tags.map((tag,index) => {
      return <div key={"tg"+index}>
        <Tag
          onRemove={() => {
            tags.splice(index, 1);
            if(excluded){
              this.setState({tags_excluded: tags});
            }
            else{
              this.setState({tags});
            }
          }}
        >{tag}</Tag>
      </div>;
    });
  }

  addCustomerTags(tags_key, temp_tags_key) {
    /* Fetch tags from state */
    var temp_customer_tags_value = this.state[temp_tags_key];
    try{
        temp_customer_tags_value = temp_customer_tags_value.replace(/,/gi, "");
    }catch(e){}
    if(temp_customer_tags_value.trim() === "") return;

    /* Check if tags are not already exists */
    var customer_tags = this.state[tags_key];
    if(customer_tags.some(tag=>tag.toLowerCase() === temp_customer_tags_value.toLowerCase())){
        this.toast("Tag already exists",true);
        return false;
    }

    /* Update tags */
    customer_tags.push(temp_customer_tags_value);
    this.setState({ [tags_key]: customer_tags, [temp_tags_key]: "" });
  }

  render(){
    const { title, message, state, customer_group, discount_type, calculation_type, temp__customer_tag, allocation_method } = this.state;

    const exlcude_tags_section = <div>
      <Stack vertical spacing="tight">
        <Form onSubmit={() => {
          this.addCustomerTags("tags_excluded", "temp__customer_tag");
        }}>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                autoComplete={false}
                label="Exclude customers"
                placeholder="e.g wholesale"
                value={temp__customer_tag}
                onChange={ (v) => {
                  this.setState({temp__customer_tag:v});
                }}
                connectedRight={
                  <Button
                      primary
                      onClick={()=>{
                          this.addCustomerTags("tags_excluded", "temp__customer_tag");
                      }}
                  >Add</Button>
                }
              />
              <div></div>
              <div></div>
            </FormLayout.Group>
          </FormLayout>
          <small><TextStyle variation="subdued">Tags are case-insensitive. "wholesale" and "Wholesale" will have same meaning</TextStyle></small>
        </Form>

      
        {
          this.state.tags_excluded && this.state.tags_excluded.length > 0 &&
          <Stack spacing="extraTight">
            {this.render_tags(this.state.tags_excluded, true)}
          </Stack>
        }
      </Stack>
    </div>;
    
    const section_0 = <div>
        <Card title="Order level discount rule" >
            <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      label="Order level discount title"
                      placeholder="e.g additional 20% discount for wholesale customers"
                      helpText="Write the title of your Order Level discount rule. This will not appear to the customers."
                      labelHidden
                      value={title}
                      onChange={(v) => {
                          this.setState({title:v})
                      }}
                    />
                    <TextField
                      label="Message"
                      placeholder="Additional Discount {{discount}}"
                      helpText="This label appears on cart and checkout pages"
                      labelHidden
                      value={message}
                      onChange={(v) => {
                          this.setState({message:v})
                      }}
                    />
                  </FormLayout.Group>
                </FormLayout>
            </Card.Section>
            <Card.Section>
                <Stack vertical spacing="extraTight">
                    <RadioButton
                        disabled={Common.getPlanID() !== 3}
                        label="Save as Published"
                        helpText="Make discount rule available for the public to view."
                        checked={"published" === state}
                        id="published"
                        onChange={(a,b) => {this.setState({state: "published"});}}
                    />
                    <RadioButton
                        label="Save as Unpublished"
                        helpText="Make discount rule unavailable for the public to view."
                        checked={"unpublished" === state}
                        id="unpublished"
                        onChange={(a,b) => {this.setState({state: "unpublished"});}}
                    />
                </Stack>
            </Card.Section>
        </Card>
    </div>;

    const section_1 = <div>
      <Card title="Select customer group to apply discount">
        <Card.Section>
          <Stack vertical spacing="extraTight">
            <Stack.Item>
            <p>Select the target customer group to apply additional Order Level discount</p>
            </Stack.Item>
              <Stack.Item>
                <Stack spacing="tight" vertical>
                    <RadioButton
                        label="Only Tagged Customers"
                        checked={customer_group === "has_tag"}
                        value={customer_group === "has_tag"}
                        onChange={(v) => {
                            this.setState({customer_group: "has_tag"});
                        }}
                    />
                    {
                      customer_group === "has_tag" &&
                        <div>

                          <Stack vertical spacing="tight">
                            <Form onSubmit={() => {
                              this.addCustomerTags("tags", "temp__customer_tag");
                            }}>
                              <FormLayout>
                                <FormLayout.Group>
                                  <TextField
                                    error={(this.state.tags.length === 0)}
                                    autoComplete={false}
                                    label="Enter customer tag(s)"
                                    placeholder="e.g wholesale"
                                    value={temp__customer_tag}
                                    onChange={ (v) => {
                                      this.setState({temp__customer_tag:v});
                                    }}
                                    connectedRight={
                                      <Button
                                          primary
                                          onClick={()=>{
                                              this.addCustomerTags("tags", "temp__customer_tag");
                                          }}
                                      >Add</Button>
                                    }
                                  />
                                  <div></div>
                                  <div></div>
                                </FormLayout.Group>
                              </FormLayout>
                              <small><TextStyle variation="subdued">Tags are case-insensitive. "wholesale" and "Wholesale" will have same meaning</TextStyle></small>
                            </Form>

                          
                            {
                              this.state.tags && this.state.tags.length > 0 &&
                              <Stack spacing="extraTight">
                                {this.render_tags(this.state.tags)}
                              </Stack>
                            }
                          </Stack>
                        </div>
                    }
                    <RadioButton
                        label="Only Logged In Customers"
                        checked={customer_group === "has_account"}
                        value={customer_group === "has_account"}
                        onChange={(v) => {
                            this.setState({customer_group: "has_account"});
                        }}
                    />
                    { customer_group === "has_account" && <div>{exlcude_tags_section}</div>}
                    <RadioButton
                        label="Only Guest Customers"
                        checked={customer_group === "no_account"}
                        value={customer_group === "no_account"}
                        onChange={(v) => {
                          if(calculation_type === 'total_spent'){
                            this.setState({customer_group: "no_account", calculation_type:"cart_subtotal"});
                          }
                          else{
                            this.setState({customer_group: "no_account"});
                          }
                        }}
                    />
                    <RadioButton
                        disabled={Common.getPlanID() !== 3}
                        label={<span>All Customers {Common.getPlanID() !== 3 && <span>(<Link url={BUILD_VERSION_EXTENSION+"/pricing-plans"}>Upgrade plan</Link>)</span>}</span>}
                        checked={customer_group === "all"}
                        value={customer_group === "all"}
                        onChange={(v) => {
                          if(calculation_type === 'total_spent'){
                            this.setState({customer_group: "all", calculation_type:"cart_subtotal"});
                          }
                          else{
                            this.setState({customer_group: "all"});
                          }
                        }}
                    />
                    { customer_group === "all" && <div>{exlcude_tags_section}</div>}
                </Stack>
              </Stack.Item>
          </Stack>
        </Card.Section>
      </Card>
    </div>;

    const section_2 = <div>
      <Card title="Apply additional discount based on">
          <Card.Section>
            <Stack spacing="extraTight" vertical>
              <RadioButton
                label="Total order amount (Cart Subtotal)"
                id="cart_subtotal"
                name="calculation_type"
                checked={calculation_type === 'cart_subtotal'}
                onChange={(c, v) => { this.setState({ calculation_type: v }) }}
              />
              <RadioButton
                label="Total order quantity (Cart Quantity)"
                id="cart_quantity"
                name="calculation_type"
                checked={calculation_type === 'cart_quantity'}
                onChange={(c, v) => { this.setState({ calculation_type: v }) }}
              />
              {
                (customer_group === "has_account" || customer_group === "has_tag") &&
                <RadioButton
                  label="Customer lifetime purchase amount (Total purchase amount of all orders)"
                  id="total_spent"
                  name="calculation_type"
                  checked={calculation_type === 'total_spent'}
                  onChange={(c, v) => { this.setState({ calculation_type: v }) }}
                />
              }
            </Stack>
          </Card.Section>
          <Card.Section title="Discount Type">
            <Stack spacing="extraLoose">
              <RadioButton
                label="Percent Off "
                id="percentage"
                name="discount_type"
                checked={discount_type === 'percentage'}
                onChange={(c, v) => { this.setState({ discount_type: v }) }}
              />
              <RadioButton
                label="Price Off"
                id="fixed_amount"
                name="discount_type"
                checked={discount_type === 'fixed_amount'}
                onChange={(c, v) => { this.setState({ discount_type: v }) }}
              />
            </Stack>
          </Card.Section>
          {
            (discount_type === 'percentage' && calculation_type !== 'total_spent') && 
            <Card.Section title="Discount allocation method">
              <Stack spacing="extraTight" vertical>
                <RadioButton
                  label="First apply other discounts such as Coupon Code and Automatic Discount if any, then calculate Percent Off amount from Cart Subtotal. (Recommended)"
                  id="post_discount"
                  name="Discount allocation method"
                  checked={allocation_method === 'post_discount'}
                  onChange={(c, v) => { this.setState({ allocation_method: v }) }}
                />
                <RadioButton
                  label="
                  First calculate Percent Off amount from Cart Subtotal, then apply other discounts such as Coupon Code and Automatic Discount if any."
                  id="pre_discount"
                  name="Discount allocation method"
                  checked={allocation_method === 'pre_discount'}
                  onChange={(c, v) => { this.setState({ allocation_method: v }) }}
                />
              </Stack>
            </Card.Section>
          }
      </Card>
    </div>;

    const section_3 = <div>
      <Card title="Discount Range">
        <Card.Section>
          {this.build_discout_range()}
        </Card.Section>
      </Card>
    </div>;
    var sv_btn = {
      loading: this.state.save_btn_loading,
      content: "Save Order Level Discount",
      onAction: () => {this.save_rule()},
      disabled: Common.getPlanID() !== 3
    };

    if(Common.getPlanID() !== 3){
      sv_btn = {
        loading: this.state.save_btn_loading,
        content: "Save Order Level Discount",
        onAction: () => {this.save_rule()},
        disabled: Common.getPlanID() !== 3,
        icon: LockMinor
      };
    }

    const page_actions = <PageActions
      secondaryActions={[
        { content: 'Cancel', url: BUILD_VERSION_EXTENSION+'/cart-discounts/list', target: "APP" },
      ]}
      primaryAction={sv_btn}
    />;

    let errors_section = <div>
      {
        this.state.errorList.length > 0 && 
        <Banner status="critical" title={`There ${this.state.errorList.length > 1?"are":"is"} ${this.state.errorList.length } ${this.state.errorList.length > 1?"errors":"error"}`}>
          <List type="number">
          {
            this.state.errorList.map((error,iN) => {
              return <List.Item key={iN}>{error}</List.Item>
            })
          }
          </List>
        </Banner>
      }
    </div>

    var toast_banner = this.state.showToast? (<ToastMessage message={this.state.toastMsg} error={this.state.toastError} onDismiss={()=>{this.setState({showToast: false})}} />):(null);
    var btn_sv = {
      disabled: Common.getPlanID() !== 3? true: this.state.save_btn_loading,
      content: "Save Order Level Discount",
      onAction: () => {this.save_rule()}
    };
    if(Common.getPlanID() !== 3){
      btn_sv = {
        disabled: Common.getPlanID() !== 3? true: this.state.save_btn_loading,
        content: "Save Order Level Discount",
        onAction: () => {this.save_rule()},
        icon: LockMinor
      };
    }
    if(this.state.page_loaded){
      return(
        <div>
          <PageTitleBar
            title={this.props.show === "edit"?"Edit Cart Pricing":"Create Cart Pricing"}
            secondaryActions={[
              { content: 'Back', url: BUILD_VERSION_EXTENSION+'/cart-discounts/list', target: 'APP' }
            ]}
            primaryAction={btn_sv}
          />
          {toast_banner}
          <Layout>
            {
              Common.getPlanID() !== 3 &&
              <Layout.Section>
                <Banner status="critical" title="Action Required">
                  <p>Cart Level Pricing feature is not available in current plan. Please <Link url={BUILD_VERSION_EXTENSION+"/pricing-plans"} target="APP" monochrome={true}>Upgrade plan</Link> to Business Plan to continue with Cart Level Pricing.</p>
                </Banner>
              </Layout.Section>
            }
            <Layout.Section>{errors_section}</Layout.Section>
            <Layout.Section>{section_0}</Layout.Section>
            <Layout.Section>{section_1}</Layout.Section>
            <Layout.Section>{section_2}</Layout.Section>
            <Layout.Section>{section_3}</Layout.Section>
            <Layout.Section>{page_actions}</Layout.Section>
          </Layout>
          {
            this.state.save_btn_loading &&
            <LoadingOverlay
              message="Saving Order Level discounts rule. Please wait..."
            />
          }
        </div>
      );
    }
    else{
      return(
        <SkeletonPageComp />
      )
    }
  }
}

function getValue(value){
	if(value){
		try {
            value = value.toString();
        } catch (e) { }
    }
    return value;
}

export default CreateCartLevelDiscount;