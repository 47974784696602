import React, { Component } from 'react';
import { ChoiceList, Link,FormLayout,Form,Card,RadioButton,Stack,Checkbox,TextStyle,Select,TextField,ColorPicker,Popover,RangeSlider,SettingToggle,PageActions,Spinner,Heading,Button,Badge,Tag } from '@shopify/polaris';
import ToastMessage from '../Common/ToastMessage';
import TitleBar from '../Common/TitleBar';
import axios from '../Axios';
import Common from '../Helpers/Common';
import store from 'store2';
var interval = 0;
const money_format = store('ws_money_format') || null;
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;
export default class GeneralSettings extends Component {
    constructor(props){
      super(props);
      this.state = {
        discount_method_value: '',
        host_name: this.props.host_name,
        shop_id: this.props.shop_id,
        shop: this.props.shop,
        isSetDiscountMethod: '',
        compareAtPrice: false,
        ShowAsCompareAtPrice: false,
        hide_cents:false,
        isProcessing_Hide_cents: false,
        isProcessing_ShowAsCompareAtPrice: false,
        isProcessing_compareAtPrice: false,

        // discount text on draft order
        saving_discountText: false,
        discount_text: "",

        sale_clock_type: '1',
        sale_clock_text1: 'Sale ends in',
        sale_clock_text2: '',
        page_loaded: false,
        clockSaveChanges: false,
        show_saleClock: true,
        bg_color: {
          hue:0,
          saturation:0,
          brightness:0
        },
        fg_color: {
          hue:0,
          saturation:0,
          brightness:1
        },
        bg_color_popup: false,
        fg_color_popup: false,
        saleclock_text_align: 'left',
        saleClockFontSize: '26',
        hex_bg_color: '#000000',
        hex_fg_color: '#ffffff',
        saleClockBorderRadius: '0',
        enable_app: true,
        default_table_style: false,
        table_bg_clr: {
          hue:0,
          saturation:0,
          brightness:1
        },
        table_fg_clr: {
          hue:0,
          saturation:0,
          brightness:0
        },
        table_bg_clr_popup: false,
        table_fg_clr_popup: false,
        table_hex_bg_clr: '#ffffff',
        table_hex_fg_clr: '#000000',
        table_template_class:'wholesale-table-striped wholesale-table-hover',
        thead_bg_clr: {
          hue:0,
          saturation:0,
          brightness:1
        },
        thead_fg_clr: {
          hue:0,
          saturation:0,
          brightness:0
        },
        thead_bg_clr_popup: false,
        thead_fg_clr_popup: false,
        thead_hex_bg_clr: '#ffffff',
        thead_hex_fg_clr: '#000000',
        table_text_align: 'left',
        table_border_hex_clr: '#ddd',
        table_border_clr:{
          hue:0,
          saturation:0,
          brightness:0.86
        },
        table_border_clr_popup: false,
        tableWidgetSaveChanges: false,
        ppdt_prefix:'This product qualifies for ',
        ppdt_suffix:' discount!',
        ppdt_additionalStyle: false,
        ppdtSaveChanges: false,
        ppdtFontSize: "14",
        ppdtTextAlign: "left",
        ppdt_hex_fg_color:"#000000",
        ppdt_fg_color_popup: false,
        ppdt_hex_bg_color: "#eeeeee",
        ppdt_bg_color_popup: false,
        ppdt_fg_color: {
          hue:0,
          saturation:0,
          brightness:0
        },
        ppdt_bg_color: {
          hue:0,
          saturation:0,
          brightness:0.93
        },
        enable_additional_coupon_code: false,
        additional_coupon_code_tag_based_customer_tags: [],
        additional_coupon_code_tag_based_enabled: false,
        temp__customer_tag: '',
        saving_coupon_field_data: false,
        disable_checkout_while_loading: false,
        logincustomization_signup_link_type: "",
        logincustomization_signup_link: "",
        logincustomization_signup_link_label: "",
        savingLoginCustomization: false,
        additionalFee_enabled: false,
        additionalFee_label: "",
        additionalFee_value: "",
        additionalFee_value_type: "",
        savingAdditionalFee: false,
        showToast: false,
        toastMsg: '',
        toastError: false,
        preventAutomaticDiscounts: false,
      }
    }
    showToastMessage(msg, error){
      this.setState({
        showToast: true,
        toastMsg: msg,
        toastError: error||false,
      });
    }
    
    dissmissToast = () =>{
      this.setState({
        showToast: false,
      });
    }

    componentDidMount(){

      // Check if parent component provide setting object
      if(typeof this.props.settings !== "undefined" && this.props.settings){
        // Use the already fetched settings instead of loading new
        this.setState(this.props.settings);
      }
      else{
      axios.get('/get-settings/'+this.props.shop)
      .then(response => {
        if(response.data.length > 0){
            var data = response.data[0];
            var tws = data.table_widget_style;
            var pp_discount_text = [];
            if(data.pp_discount_text && data.pp_discount_text.length > 0){
              pp_discount_text = data.pp_discount_text[0];
            }
            try{
              this.setState({
                page_loaded: true,
                discount_method_value: data.discount_method,
                compareAtPrice: data.compare_at_price,
                ShowAsCompareAtPrice: data.show_compare_at_price,
                hide_cents: data.hide_cents,
                sale_clock_type: data.sale_clock.sale_clock_type,
                sale_clock_text1: data.sale_clock.sale_clock_text1,
                sale_clock_text2: data.sale_clock.sale_clock_text2,
                show_saleClock: data.sale_clock.show_sale_clock,
                bg_color: data.sale_clock.style.bg_color[0],
                fg_color: data.sale_clock.style.fg_color[0],
                saleClockFontSize: data.sale_clock.style.font_size,
                saleclock_text_align: data.sale_clock.style.text_align,
                hex_bg_color: data.sale_clock.style.hex_bg_color,
                hex_fg_color: data.sale_clock.style.hex_fg_color,
                saleClockBorderRadius: data.sale_clock.style.border_radius,
                enable_app: data.enable_app,
                default_table_style: tws.length > 0?tws[0].default_table_style:this.state.default_table_style,
                table_bg_clr: tws.length>0?tws[0].table_bg_clr:this.state.table_bg_clr,
                table_fg_clr: tws.length>0?tws[0].table_fg_clr:this.state.table_fg_clr,
                table_hex_bg_clr: tws.length>0?tws[0].table_hex_bg_clr:this.state.table_hex_bg_clr,
                table_hex_fg_clr: tws.length>0?tws[0].table_hex_fg_clr:this.state.table_hex_fg_clr,
                table_template_class: tws.length>0?tws[0].table_template_class:this.state.table_template_class,
                thead_bg_clr: tws.length>0?tws[0].thead_bg_clr:this.state.thead_bg_clr,
                thead_fg_clr: tws.length>0?tws[0].thead_fg_clr:this.state.thead_fg_clr,
                thead_hex_bg_clr: tws.length>0?tws[0].thead_hex_bg_clr:this.state.thead_hex_bg_clr,
                thead_hex_fg_clr: tws.length>0?tws[0].thead_hex_fg_clr:this.state.thead_hex_fg_clr,
                table_text_align: tws.length>0?tws[0].table_text_align:this.state.table_text_align,
                table_border_hex_clr: tws.length>0?tws[0].table_border_hex_clr:this.state.table_border_hex_clr,
                table_border_clr: tws.length>0?tws[0].table_border_clr:this.state.table_border_clr,
                isSetDiscountMethod: true,
                ppdt_suffix: pp_discount_text.text?pp_discount_text.text.suffix:this.state.ppdt_suffix,
                ppdt_prefix: pp_discount_text.text?pp_discount_text.text.prefix: this.state.ppdt_prefix,
                ppdt_additionalStyle: pp_discount_text.style?pp_discount_text.style.ppdt_additionalStyle: this.state.ppdt_additionalStyle,
                ppdtFontSize: pp_discount_text.style?pp_discount_text.style.ppdtFontSize: this.state.ppdtFontSize,
                ppdtTextAlign: pp_discount_text.style?pp_discount_text.style.ppdtTextAlign: this.state.ppdtTextAlign,
                ppdt_fg_color: pp_discount_text.style?pp_discount_text.style.ppdt_fg_color: this.state.ppdt_fg_color,
                ppdt_bg_color: pp_discount_text.style?pp_discount_text.style.ppdt_bg_color: this.state.ppdt_bg_color,
                ppdt_hex_fg_color: pp_discount_text.style?pp_discount_text.style.ppdt_hex_fg_color: this.state.ppdt_hex_fg_color,
                ppdt_hex_bg_color: pp_discount_text.style?pp_discount_text.style.ppdt_hex_bg_color: this.state.ppdt_hex_bg_color,
                enable_additional_coupon_code: data.enable_additional_coupon_code?data.enable_additional_coupon_code: this.state.enable_additional_coupon_code,
                disable_checkout_while_loading: data.disable_checkout_while_loading || false,
                logincustomization_signup_link_type: data.logincustomization_signup_link_type || "append",
                logincustomization_signup_link: data.logincustomization_signup_link || "/pages/register",
                logincustomization_signup_link_label: data.logincustomization_signup_link_label || "Create wholesale account",
                additionalFee_enabled: data.additionalFee_enabled || false,
                additionalFee_label: data.additionalFee_label || "Processing Fee",
                additionalFee_value: data.additionalFee_value || "0",
                additionalFee_value_type: data.additionalFee_value_type || "fixed",
                additional_coupon_code_tag_based_customer_tags: data.additional_coupon_code_tag_based  ? data.additional_coupon_code_tag_based.tags : [],
                additional_coupon_code_tag_based_enabled: data.additional_coupon_code_tag_based ? data.additional_coupon_code_tag_based.enabled : false,
                preventAutomaticDiscounts: data.preventAutomaticDiscounts || false,
              });
            }catch(e){
              // console.log("ERROR:",e);
            }
          }else{
            this.setState({isSetDiscountMethod: false});
            this.showToastMessage("Select a discount method.", true);
          }
      })
      .catch(error => {
          this.handleAxiosError(error);
      })
    }
  }

  addInCustomer_tags() {
    var customer_tags = this.state.additional_coupon_code_tag_based_customer_tags;
    var temp__customer_tag = this.state.temp__customer_tag;
    try {
        temp__customer_tag = temp__customer_tag.replace(/,/gi, "");
    } catch (e) { }
    if (temp__customer_tag.trim() === "") return;
    try {
        if (-1 === customer_tags.findIndex(x => x.trim().toLowerCase() === temp__customer_tag.trim().toLowerCase())) {
            customer_tags.push(temp__customer_tag.trim());
        }
        else {
            this.toast("Tag already exists", true)
        }
    } catch (e) { }
    this.setState({ additional_coupon_code_tag_based_customer_tags: customer_tags, temp__customer_tag: "", });
  }

  renderIncludedcustomer_tags() {
    let customer_tags = this.state.additional_coupon_code_tag_based_customer_tags;
    return customer_tags.map((tag, j) => {
        return <Tag key={j}
            onRemove={() => {
                var customer_tags = [...this.state.additional_coupon_code_tag_based_customer_tags];
                customer_tags.splice(j, 1);
                this.setState({ additional_coupon_code_tag_based_customer_tags: customer_tags });
            }}
        >
            {
                tag === "has_account" ?
                    "All Login Customers"
                    : tag === "tag_based" ?
                        "Specific Tagged Customers"
                        : tag
            }
        </Tag>
    })
}

    // CALL: send update call to server
    sendUpdateCall(payload){
      // sent PUT request to CrudOperation238.js

      // Check if shop not found on payload, append it
      if(!(typeof payload.shop !== "undefined" && payload.shop && payload.shop !== ""))payload.shop = this.props.shop;

      return axios.put('/app/v238/general-settings', payload);
    }

    // Axios Error handing
    handleAxiosError(error){
      let errorMsg = "Something went wrong, please try again after some time";
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(error.response.data && error.response.data.message) errorMsg = error.response.data.message;
      }

      // Show error toast
      this.showToastMessage(errorMsg, true);
    }

    saveDisableCheckout = (value) => {
      this.setState({disable_checkout_while_loading: value});
      const settingsData = {
        disable_checkout_while_loading: value,
        shop: this.state.shop
      };
      this.sendUpdateCall(settingsData)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
        }
        else{
          this.showToastMessage(response.data.message, true);
        }
      })
      .catch(error => {
        this.handleAxiosError(error);
      });
    };
    saveCouponCodeField = () => {
      if(this.state.additional_coupon_code_tag_based_enabled && this.state.additional_coupon_code_tag_based_customer_tags.length === 0){
        this.showToastMessage("Please add customer tags", true);
        return;
      }
      this.setState({saving_coupon_field_data: true});
      const settingsData = {
        enable_additional_coupon_code: this.state.enable_additional_coupon_code,
        additional_coupon_code_tag_based: {
          enabled: this.state.additional_coupon_code_tag_based_enabled,
          tags: this.state.additional_coupon_code_tag_based_customer_tags
        },
        preventAutomaticDiscounts: this.state.preventAutomaticDiscounts,
        shop: this.state.shop,
      };
      this.sendUpdateCall(settingsData)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
          this.setState({saving_coupon_field_data: false});
        }
        else{
          this.showToastMessage(response.data.message, true);
          this.setState({saving_coupon_field_data: false});
        }
      })
      .catch(error => {
        this.handleAxiosError(error);
        this.setState({saving_coupon_field_data: false});
      });
    };
    handleDiscountMethodChange = (checked, newValue) => {
      this.setState({ discount_method_value: newValue });
      const settingsData = {
          discount_method: newValue,
          shop: this.state.shop,
          shop_id: this.state.shop_id,
      };
      this.sendUpdateCall(settingsData)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        }
      }).catch(error => {
        this.handleAxiosError(error);
      });
    };
    saveSetting = (settingsData) => {
      this.sendUpdateCall(settingsData)
      .then( response => {
        this.setState({savingLoginCustomization: false, savingAdditionalFee:false, saving_discountText: false});
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        }
      }).catch(error => {
        this.handleAxiosError(error);
      });
    }
    handleAdditionalFeeSubmit = () => {

      let additionalFee_value = this.state.additionalFee_value;
      if(this.state.additionalFee_value_type === "fixed"){
        additionalFee_value = Common.getCurrency(additionalFee_value, money_format).amount;
      }
      else{
        additionalFee_value = parseFloat(additionalFee_value)||0;
      }
      if(typeof additionalFee_value === "number")additionalFee_value=additionalFee_value.toString();

      this.setState({savingAdditionalFee: true, additionalFee_value: additionalFee_value});
      
      this.handleLoginCustomizationChange([
        {
          key:"additionalFee_enabled",
          value: this.state.additionalFee_enabled
        },
        {
          key:"additionalFee_label",
          value: this.state.additionalFee_label
        },
        {
          key:"additionalFee_value",
          value: additionalFee_value
        },
        {
          key:"additionalFee_value_type",
          value: this.state.additionalFee_value_type
        }
      ]);
    }
    handleLoginCustomizationSubmit = () => {
      this.setState({savingLoginCustomization: true});
      /* Prefix with "/pages/" */
      let logincustomization_signup_link_splitted = this.state.logincustomization_signup_link.split('/');
      let logincustomization_signup_link = logincustomization_signup_link_splitted.length > 0 ? logincustomization_signup_link_splitted[logincustomization_signup_link_splitted.length-1] : logincustomization_signup_link_splitted;
      logincustomization_signup_link = "/pages/"+logincustomization_signup_link;

      this.handleLoginCustomizationChange([
        {
          key:"logincustomization_signup_link_type",
          value: this.state.logincustomization_signup_link_type
        },
        {
          key:"logincustomization_signup_link",
          value: logincustomization_signup_link
        },
        {
          key:"logincustomization_signup_link_label",
          value: this.state.logincustomization_signup_link_label
        }
      ]);
    }
    handleLoginCustomizationChange = (payload) => {
      const obj = {};
      payload.forEach(function(item){
        obj[item.key] = item.value;
      });
      const settingsData = {
        ...obj,
        shop: this.state.shop,
      };
      this.saveSetting(settingsData);
    };
    handleHide_cents = (value) => {
      this.setState({hide_cents: value, isProcessing_Hide_cents: true});
      
      const settingsData = {
        hide_cents: value,
        shop: this.state.shop
      };
      this.sendUpdateCall(settingsData)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        }
        this.setState({isProcessing_Hide_cents: false});
      })
      .catch(error => {
        this.handleAxiosError(error);
      });
    };
    handleShowAsCompareAtPrice = (value) => {
      this.setState({ShowAsCompareAtPrice: value, isProcessing_ShowAsCompareAtPrice: true});
      const settingsData = {
        show_compare_at_price: value,
        shop: this.state.shop
      };
      this.sendUpdateCall(settingsData)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        }
        this.setState({isProcessing_ShowAsCompareAtPrice: false});
      })
      .catch(error => {
        this.handleAxiosError(error);
      });
    };
    handleCompareAtPrice = (value) => {
      this.setState({compareAtPrice: value,isProcessing_compareAtPrice: true});
      const settingsData = {
        compare_at_price: value,
        shop: this.state.shop,
        shop_id: this.state.shop_id
      };
      this.sendUpdateCall(settingsData)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        }
        this.setState({isProcessing_compareAtPrice: false});
      })
      .catch(error => {
        this.handleAxiosError(error);
      });
    };
    saveClockType(){
      let text1 = this.state.sale_clock_text1 === ''?'Sale ends in':this.state.sale_clock_text1;
      let text2 = this.state.sale_clock_text2;
      let clockType = this.state.sale_clock_type;
      let show_sale_clock = this.state.show_saleClock;
      const data = {
        shop: this.state.shop,
        shop_id: this.state.shop_id,
        sale_clock: {
          sale_clock_text1: text1,
          sale_clock_text2: text2,
          sale_clock_type: clockType,
          show_sale_clock: show_sale_clock,
          style:{
            font_size: this.state.saleClockFontSize,
            bg_color: this.state.bg_color,
            fg_color: this.state.fg_color,
            text_align: this.state.saleclock_text_align,
            padding: '0.5rem 1rem',
            border_radius: this.state.saleClockBorderRadius,
            hex_bg_color: this.state.hex_bg_color,
            hex_fg_color: this.state.hex_fg_color
          }
        }
      };
      this.sendUpdateCall(data)
      .then( response => {
        if(response.data.status === 1){
          this.setState({clockSaveChanges:false});
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        } 
      })
      .catch(error => {
        this.handleAxiosError(error);
      });
    }
    saveTableWidget(){
      const tableWidgetData = {
        shop: this.state.shop,
        shop_id: this.state.shop_id,
        table_widget_style: {
          default_table_style: this.state.default_table_style,
          table_bg_clr: this.state.table_bg_clr,
          table_fg_clr: this.state.table_fg_clr,
          table_hex_bg_clr: this.state.table_hex_bg_clr,
          table_hex_fg_clr: this.state.table_hex_fg_clr,
          table_template_class: this.state.table_template_class,
          thead_bg_clr: this.state.thead_bg_clr,
          thead_fg_clr: this.state.thead_fg_clr,
          thead_hex_bg_clr: this.state.thead_hex_bg_clr,
          thead_hex_fg_clr: this.state.thead_hex_fg_clr,
          table_text_align: this.state.table_text_align,
          table_border_hex_clr: this.state.table_border_hex_clr,
          table_border_clr: this.state.table_border_clr	
        }
      };
      this.sendUpdateCall(tableWidgetData)
      .then( response => {
        if(response.data.status === 1){
          this.setState({tableWidgetSaveChanges:false});
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        }
      })
      .catch(error => {
        this.handleAxiosError(error);
      });
    }
    savePPDT(){
      const text_and_style = {
        shop: this.state.shop,
        shop_id: this.state.shop_id,
        pp_discount_text: {
          "text":{
            prefix: this.state.ppdt_prefix,
            suffix: this.state.ppdt_suffix
          },
          "style":{
            ppdt_additionalStyle: this.state.ppdt_additionalStyle,
            ppdtFontSize: this.state.ppdtFontSize,
            ppdtTextAlign: this.state.ppdtTextAlign,
            ppdt_fg_color: this.state.ppdt_fg_color,
            ppdt_bg_color: this.state.ppdt_bg_color,
            ppdt_hex_fg_color: this.state.ppdt_hex_fg_color,
            ppdt_hex_bg_color: this.state.ppdt_hex_bg_color
          }
        }
      };
      this.sendUpdateCall(text_and_style)
      .then( response => {
        if(response.data.status === 1){
          this.setState({ppdtSaveChanges:false});
          this.showToastMessage(response.data.message);
        }else{
          this.showToastMessage(response.data.message, true);
        }
      })
      .catch(error => {
        this.handleAxiosError(error);
      });
    }
    ppdtResetToDefault(){
      this.setState({
        ppdt_prefix:'This product qualifies for ',
        ppdt_suffix:' discount!',
        ppdt_additionalStyle: false,
        ppdtSaveChanges: true,
        ppdtFontSize: "14",
        ppdtTextAlign: "left",
        ppdt_hex_fg_color:"#000000",
        ppdt_fg_color_popup: false,
        ppdt_hex_bg_color: "#eeeeee",
        ppdt_bg_color_popup: false,
        ppdt_fg_color: {
          hue:0,
          saturation:0,
          brightness:0
        },
        ppdt_bg_color: {
          hue:0,
          saturation:0,
          brightness:0.93
        }
      });
    }
    hueToRgb(t1, t2, hue) {
      if (hue < 0) hue += 6;
      if (hue >= 6) hue -= 6;
      if (hue < 1) return (t2 - t1) * hue + t1;
      else if(hue < 3) return t2;
      else if(hue < 4) return (t2 - t1) * (4 - hue) + t1;
      else return t1;
    }
    rgbToHex (red,green,blue) {
      var r = this.toHex(red);
      var g = this.toHex(green);
      var b = this.toHex(blue);
      return "#" +  r + g + b;
    }
    toHex(n) {
      var hex = n.toString(16);
      while (hex.length < 2) {hex = "0" + hex; }
      return hex;
    }
    hslToRgb(hue, sat, light) {
      var t1, t2, r, g, b;
      hue = hue / 60;
      if ( light <= 0.5 ) {
        t2 = light * (sat + 1);
      } else {
        t2 = light + sat - (light * sat);
      }
      t1 = light * 2 - t2;
      r = this.hueToRgb(t1, t2, hue + 2) * 255;
      g = this.hueToRgb(t1, t2, hue) * 255;
      b = this.hueToRgb(t1, t2, hue - 2) * 255;
      r = Number(r.toFixed(0));
      g = Number(g.toFixed(0));
      b = Number(b.toFixed(0));
      return {r:r,g:g,b:b};
    }
    handleEnableApp = () => {
      this.setState(({enable_app}) => {
        return {enable_app: !enable_app};
      });
      const settingsData = {
        enable_app: !this.state.enable_app,
        shop: this.state.shop,
        shop_id: this.state.shop_id
      };
      this.sendUpdateCall(settingsData)
      .then( response => {
          if(response.data.status === 1){
            this.showToastMessage(response.data.message);
          }else{
            this.showToastMessage(response.data.message, true);
          }
      })
      .catch(error => {
        this.handleAxiosError(error);
      });
    };
    setSaleClockToDefault(){
      this.setState({
        sale_clock_type: '1',
        sale_clock_text1: 'Sale ends in',
        sale_clock_text2: '',
        clockSaveChanges: true,
        show_saleClock: true,
        bg_color: {
          hue:0,
          saturation:0,
          brightness:0
        },
        fg_color: {
          hue:0,
          saturation:0,
          brightness:1
        },
        bg_color_popup: false,
        fg_color_popup: false,
        saleclock_text_align: 'left',
        saleClockFontSize: '26',
        hex_bg_color: '#000000',
        hex_fg_color: '#ffffff',
        saleClockBorderRadius: '0',
      });
    }
    setTableWidgetToDefault(){
      this.setState({
        default_table_style: false,
        table_bg_clr: {
          hue:0,
          saturation:0,
          brightness:1
        },
        table_fg_clr: {
          hue:0,
          saturation:0,
          brightness:0
        },
        table_bg_clr_popup: false,
        table_fg_clr_popup: false,
        table_hex_bg_clr: '#ffffff',
        table_hex_fg_clr: '#000000',
        table_template_class:'wholesale-table-striped wholesale-table-hover',
        thead_bg_clr: {
          hue:0,
          saturation:0,
          brightness:1
        },
        thead_fg_clr: {
          hue:0,
          saturation:0,
          brightness:0
        },
        thead_bg_clr_popup: false,
        thead_fg_clr_popup: false,
        thead_hex_bg_clr: '#ffffff',
        thead_hex_fg_clr: '#000000',
        table_text_align: 'left',
        table_border_hex_clr: '#ddd',
        table_border_clr:{
          hue:0,
          saturation:0,
          brightness:0.86
        },
        table_border_clr_popup: false,
        tableWidgetSaveChanges: true
      });
    }
    countDownClock(end,clockId){
      try{
        this.setCountDownClock(this.state.sale_clock_type,end,clockId);
      }
      catch(e){}
    }
    clearAllInterval = (interval) => {
      for (var i = 1; i <= interval; i++){
        clearInterval(i);
      }
    };
    setCountDownClock(sale_clock_type,end,clockId){
      clearInterval(interval);
      let result;
      let start = new Date('2019-04-20 12:00:55');
      try{
        if(typeof start !== 'object' || typeof end !== 'object'){return undefined; }
        start = start.getTime();
        end = end.getTime();
        var timeSpan = end - start;
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(timeSpan / (1000 * 60 * 60 * 24));
        var hours = Math.floor((timeSpan % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((timeSpan % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((timeSpan % (1000 * 60)) / 1000);
        result = {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds
        };
        if(result.seconds < 0){
          result.minutes--;
          result.seconds += 60;
        }
        if(result.minutes < 0){
          result.hours--;
          result.minutes += 60;
        }
        if(result.hours < 0){
          result.days--;
          result.hours += 24;
        }
        if(result.days > 0 || result.hours > 0 || result.minutes > 0 || result.seconds > 0){
          interval = setInterval(startCountDown,1000);
        }
        function startCountDown(){
          result.seconds--;
          if(result.seconds < 0){
            result.minutes--;
            result.seconds += 60;
          }
          if(result.minutes < 0){
            result.hours--;
            result.minutes += 60;
          }
          if(result.hours < 0){
            result.days--;
            result.hours += 24;
          }
          if(result.days < 0 || result.hours < 0 || result.minutes < 0 || result.seconds < -1){
            clearInterval(interval);
            if(clockId !== null){
              try{
                let CLOCKID = document.getElementById(clockId);
                if(CLOCKID !== null){
                  CLOCKID.innerHTML = '';
                }
              }catch(e){}
            }
            try{
              window.location.reload(true);
            }catch(e){}
          }
          else{
            let ends_message = '';
            if(result.days > 0){
              if(sale_clock_type === 1){
                ends_message += result.days+'days, ';
              }
              else if(sale_clock_type === 9){
                ends_message += result.days+'days ';
              }
              else{
                ends_message += result.days+'d: ';
              }
            }
            if(result.hours > 0){
              if(sale_clock_type === 1){
                ends_message += result.hours+'hours, ';
              }
              else if(sale_clock_type === 9){
                ends_message += result.hours+'hours ';
              }
              else{
                ends_message += result.hours+'h: ';
              }
            }
            if(result.minutes > 0){
              let minutes = result.minutes;
              if(result.minutes < 10){
                minutes = '0'+minutes;
              }
              if(sale_clock_type === 1){
                ends_message += minutes+'minutes, ';
              }
              else if(sale_clock_type === 9){
                ends_message += minutes+'minutes ';
              }
              else{
                ends_message += minutes+'m: ';
              }
            }
            if(result.seconds >= 0){
              let seconds = result.seconds;
              if(result.seconds < 10){
                seconds = '0'+seconds;
              }
              if(sale_clock_type === 1){
                ends_message += seconds+'seconds';
              }
              else if(sale_clock_type === 9){
                ends_message += seconds+'seconds';
              }
              else{
                ends_message += seconds+'s';
              }
            }
            if(ends_message !== ''){
              if(clockId !== null){
                try{
                  let CLOCKID = document.getElementById(clockId);
                  if(CLOCKID !== null){
                    CLOCKID.innerHTML = ends_message;
                  }
                }catch(e){
                  clearInterval(interval);
                }
              }
            }
          }
          window.onbeforeunload = function(){
            clearInterval(interval);
          };
        }
        return result;
      }
      catch(e){
        clearInterval(interval);
      }
    }
    
    saveAdditionalDiscountCodeField(value){
      var data = {
        shop: this.state.shop,
        enable_additional_coupon_code: value
      };
      this.sendUpdateCall(data)
      .then( response => {
        if(response.data.status === 1){
          this.showToastMessage(response.data.message);
        }
        else{
          this.showToastMessage(response.data.message, true);
        }
      })
      .catch(error => {
        this.handleAxiosError(error);
      });
    }
    
    render() {
    const {
      discount_method_value,compareAtPrice,isProcessing_compareAtPrice, ShowAsCompareAtPrice,isProcessing_ShowAsCompareAtPrice,sale_clock_type,sale_clock_text2,sale_clock_text1,clockSaveChanges,show_saleClock,bg_color,fg_color,bg_color_popup,fg_color_popup,saleclock_text_align,saleClockFontSize,hex_bg_color,hex_fg_color,saleClockBorderRadius,enable_app,enable_additional_coupon_code,additional_coupon_code_tag_based_enabled,preventAutomaticDiscounts
    } = this.state;
    
    const contentStatus = enable_app ? 'Disable app' : 'Enable app';
    const textStatus = enable_app ? 'enabled' : 'disabled';
    let saleClockOptions = [
      {
        label:'2days, 1hour, 59minutes, 43seconds (Count down clock)',value: '1'
      },
      {
        label:'2days 1hour 59minutes 42seconds (Count down clock)',value: '9'
      },
      {
        label:'2d:1h:45m:42s (Count down clock)',value:'8'
      },
      {
        label:'Friday, June 16, 2019, 5:12:26 PM',value:'3'
      },
      {
        label:'Jun 16, 2019, 5:12:55 PM',value:'6'
      },
      {
        label:'Friday, June 16, 2019',value:'4'
      },
      {
        label:'June 16, 2019',value:'5'
      },
      {
        label:'Jun 16, 2019',value:'7'
      },
      {
        label:'2 days',value:'2'
      }
    ];

    const bg_color_activator = (
      <TextField
        readOnly={false}
        label="Background color"
        value={hex_bg_color}
        autoComplete={false}
        onChange={(value)=>{
          this.setState({hex_bg_color:value,bg_color_popup:false,clockSaveChanges:true});
        }}
        onFocus={()=>{
          this.setState({bg_color_popup:true});
        }}
      />
    );

    const fg_color_activator = (
      <TextField
        readOnly={false}
        label="Foreground color"
        value={hex_fg_color}
        autoComplete={false}
        onChange={(value)=>{
          this.setState({hex_fg_color:value,fg_color_popup:false,clockSaveChanges:true});
        }}
        onFocus={()=>{
          this.setState({fg_color_popup:true});
        }}
      />
    );

    const previewStyle = {
      background:hex_bg_color?hex_bg_color:'hsla('+bg_color.hue+','+bg_color.saturation*100+'%,'+bg_color.brightness*100+'%,1)',
      color:hex_fg_color?hex_fg_color:'hsla('+fg_color.hue+','+fg_color.saturation*100+'%,'+fg_color.brightness*100+'%,1)',textAlign:saleclock_text_align,
      fontSize:saleClockFontSize+'px',
      padding:'1rem 1rem',
      borderRadius:saleClockBorderRadius+'px',
      lineHeight: 'initial'
    };
        
      let section_appEnableDisable = <div style={{marginBottom:'2rem'}}>
        <SettingToggle
          action={{
            content: contentStatus,
            onAction: this.handleEnableApp,
          }}
          enabled={enable_app}
        >
          Application is <strong>{textStatus}</strong>
        </SettingToggle>
      </div>;
    
      let section_discountMethod = <div>
        <Stack vertical>

          <RadioButton
            label="Draft Order API"
            helpText="Only those orders will process via wholesale app which are eliglble for discount or shipping from the wholesale application."
            checked={discount_method_value === 'draft_order_if_eligible'}
            id="draft_order_if_eligible"
            name="discount_method"
            onChange={this.handleDiscountMethodChange}
          />
          {
            discount_method_value === 'draft_order_if_eligible' &&
            <div style={{marginLeft:'2.9rem'}}>
              <Form onSubmit={() => {
                this.setState({saving_discountText: true});

                this.handleLoginCustomizationChange([
                  {
                    key:"discount_text",
                    value: this.state.discount_text
                  }
                ]);
              }}>
                <Stack spacing="tight" alignment="center">

                  <TextField
                    value={this.state.discount_text}
                    label="Discount label"
                    onChange={(text)=>{this.setState({discount_text:text.toString()})}}
                    helpText={
                      <>
                        <span>This label will appear on the checkout and order pages </span> 
                          <Link plain url="https://support.digitalcoo.com/hc/en-us/articles/10283305493268" external>Learn more</Link>
                      </> 
                    }
                    
                  />

                  <Button 
                    submit
                    primary 
                    loading={this.state.saving_discountText} 
                    disabled={this.state.saving_discountText}
                  >
                    Save
                  </Button>

                </Stack>
              </Form>
            </div>
          }
         {/* <RadioButton
            label="Draft Order discount method for all orders"
            helpText="All the orders will process via wholesale application even if there is no discount on the order. "
            checked={discount_method_value === 'line_items'}
            id="line_items"
            name="discount_method"
            onChange={this.handleDiscountMethodChange}
        /> */}
          <RadioButton
            label="Coupon Code API"
            helpText="A random discount code will automatically apply to the order on checkout page for only those orders which are eligible for discount. In case of shipping from the wholesale app then Draft Order discount method will use instead of Coupon Code Method."
            id="coupon_code"
            name="discount_method"
            checked={discount_method_value === 'coupon_code'}
            onChange={this.handleDiscountMethodChange}
          />
          <div>
            <TextStyle variation="subdued">
              <TextStyle variation="strong">Note: </TextStyle> Shipping Charges, Only apply for Draft Order method.
            </TextStyle>
          </div>
        </Stack>
      </div>
    
      let section_priceControl = <div>
        <Stack vertical>
          {/* <Checkbox
            label={`Enable additional coupon code field at cart page`}
            value={this.state.enable_additional_coupon_code}
            checked={this.state.enable_additional_coupon_code}
            onChange={(value) => {
              this.saveAdditionalDiscountCodeField(value);
              this.setState({
                enable_additional_coupon_code: value
              });
            }}
            id="enable-additional-coupon"
            name="enable_additional_coupon_code"
          ></Checkbox> */}
          <Checkbox
            checked={compareAtPrice}
            label="Show crossout price"
            onChange={this.handleCompareAtPrice}
            disabled={isProcessing_compareAtPrice}
          /> 
          <Stack spacing="tight" alignment="center">
            <Checkbox checked={ShowAsCompareAtPrice}
              label="Show 'compare at price' as crossout price"
              onChange={this.handleShowAsCompareAtPrice}
              disabled={isProcessing_ShowAsCompareAtPrice}
            />
            <span>
              [ <Link url="https://support.digitalcoo.com/hc/en-us/articles/360045243151" external>View Example</Link> ]
            </span>
          </Stack>
          {/* <Checkbox
            checked={hide_cents}
            label="Hide decimal points when decimal value is equal to .00"
            onChange={this.handleHide_cents}
            disabled={isProcessing_Hide_cents}
          /> */}
          {/* <Checkbox
            checked={disable_checkout_while_loading}
            label="Disable checkout until wholesale app load"
            onChange={this.saveDisableCheckout}
          /> */}
        </Stack>
      </div>;
    
      let coupon_code_field_section = 
        <Card title="Coupon code field">
          <Card.Section>
        <div className="wsaio_discount_grid">
          <div className="wsaio_g_left">
            <Stack spacing="tight" vertical>
                  <RadioButton
                      label="Enable for all customers"
                      checked={enable_additional_coupon_code && !additional_coupon_code_tag_based_enabled}
                      id="all"
                      name="enable_additional_coupon_code"
                      onChange={(v, id) => {
                          this.setState({ enable_additional_coupon_code: true, additional_coupon_code_tag_based_enabled: false })
                      }}
                  />

                  <RadioButton
                      label="Enable for specific tagged customers"
                      checked={additional_coupon_code_tag_based_enabled}
                      name="additional_coupon_code_tag_based_enabled"
                      onChange={(v, id) => {
                          this.setState({ additional_coupon_code_tag_based_enabled: true, enable_additional_coupon_code: false})
                      }}
                  />

                    {
                      additional_coupon_code_tag_based_enabled === true && <div className="ws-card">
                          <Stack vertical spacing="tight">
                              {
                                  <Form
                                      onSubmit={() => {
                                          this.addInCustomer_tags();
                                      }}
                                  >
                                      <TextField
                                          error={this.state.additional_coupon_code_tag_based_customer_tags.length < 1 && "Add atleast one customer tag!"}
                                          label="Enter customers tag"
                                          value={this.state.temp__customer_tag}
                                          onChange={(v) => {
                                              this.setState({ temp__customer_tag: v })
                                          }}
                                          connectedRight={<div style={{ marginLeft: "5px" }}>
                                              <Button primary spacing="5px"
                                                  disabled={!this.state.temp__customer_tag}
                                                  onClick={() => {
                                                      this.addInCustomer_tags();
                                                  }}
                                              >
                                                  Add Tag
                                              </Button>
                                          </div>}
                                      />
                                  </Form>
                              }

                              {
                                  this.state.additional_coupon_code_tag_based_customer_tags.length > 0 && additional_coupon_code_tag_based_enabled &&
                                  <Stack>{this.renderIncludedcustomer_tags()}</Stack>
                              }
                          </Stack>
                      </div>
                  }

                  <RadioButton
                      label="Disabled"
                      checked={!enable_additional_coupon_code && !additional_coupon_code_tag_based_enabled}
                      name="enable_additional_coupon_code"
                      onChange={(v, id) => {
                          this.setState({ enable_additional_coupon_code: false, additional_coupon_code_tag_based_enabled: false })
                      }}
                  />
              </Stack>
            </div>
              <div className="wsaio_g_right">
                  {
                      enable_additional_coupon_code &&
                      <div>
                          <Heading>All</Heading>
                          <p>The coupon code field will be visible to all wholesale customers at checkout.</p>
                      </div>
                  }
                  {
                      additional_coupon_code_tag_based_enabled &&
                      <div>
                          <Heading>Only Tagged Customers</Heading>
                          <p>The coupon code field will only be visible at checkout to the wholesale customers with the specified tags once they are logged in.</p>
                      </div>
                  }
                  {
                      (!enable_additional_coupon_code && !additional_coupon_code_tag_based_enabled) &&
                      <div>
                          <Heading>Disabled</Heading>
                          <p>The coupon code field will not be visible to any customer</p>
                      </div>
                  }
              </div>
          </div>
          </Card.Section>
          <Card.Section>
            <Checkbox
              label="Prevent Shopify automatic discounts for wholesale orders"
              checked={preventAutomaticDiscounts}
              onChange={(value)=>{this.setState({preventAutomaticDiscounts:value})}}
            ></Checkbox>
          </Card.Section>

          <Card.Section>
            <Button 
              onClick={this.saveCouponCodeField}
              primary 
              loading={this.state.saving_coupon_field_data} 
              disabled={this.state.saving_coupon_field_data}
            >
              Save
            </Button>
          </Card.Section>
        </Card>;
      
    
      let section_salesClock = <div>
      <Stack vertical>
        <Checkbox
          label="Show sale clock on product page"
          checked={show_saleClock}
          onChange={(value)=>{this.setState({show_saleClock:value,clockSaveChanges:true})}}
        ></Checkbox>
        {
          show_saleClock?
          <div>
            <Stack wrap={false} alignment="leading" spacing="tight">
              <Stack.Item fill>
                <Stack distribution="fill" spacing="tight">
                  <TextField
                    value={sale_clock_text1}
                    label="Prefix"
                    labelHidden={true}
                    onChange={(text)=>{this.setState({sale_clock_text1:text,clockSaveChanges:true})}}
                    error={sale_clock_text1 === ''?true:false}
                  ></TextField>
                  <Select
                    label={sale_clock_text1 || 'Sale ends in'}
                    labelInline={true}
                    placeholder={'Select sale clock'}
                    options={saleClockOptions}
                    value={sale_clock_type}
                    onChange={(value)=>{ this.setState({sale_clock_type:value,clockSaveChanges: true})}}
                  ></Select>
                  <TextField
                    label="Suffix text"
                    placeholder="Custom text"
                    labelHidden={true}
                    value={sale_clock_text2}
                    onChange={(text)=>{this.setState({sale_clock_text2:text,clockSaveChanges:true})}}
                  ></TextField>
                </Stack>
              </Stack.Item>
            </Stack>
            <div style={{marginBottom:'1.5rem',marginTop:'1.5rem'}}>
              <Stack wrap={false} alignment="leading" spacing="tight">
                <Stack.Item fill>
                  <Stack distribution="fill" spacing="tight">
                    <Popover
                      active={bg_color_popup}
                      activator={bg_color_activator}
                      onClose={(value)=>{ this.setState({bg_color_popup:false})}}
                      preferredPosition="mostSpace"
                      sectioned
                    >
                    <ColorPicker
                      onChange={(value)=>{
                        let rgb  = this.hslToRgb(value.hue,value.saturation,value.brightness);
                        let hex = this.rgbToHex(rgb.r,rgb.g,rgb.b);
                        this.setState({bg_color:value,hex_bg_color:hex,clockSaveChanges:true});
                      }}
                      color={bg_color}
                    />
                    </Popover>
                    <Popover
                      active={fg_color_popup}
                      activator={fg_color_activator}
                      onClose={(value)=>{ this.setState({fg_color_popup:false})}}
                      preferredPosition="mostSpace"
                      sectioned
                    >
                    <ColorPicker
                      onChange={(value)=>{
                        let rgb  = this.hslToRgb(value.hue,value.saturation,value.brightness);
                        let hex = this.rgbToHex(rgb.r,rgb.g,rgb.b);
                        this.setState({fg_color:value,hex_fg_color:hex,clockSaveChanges:true});
                      }}
                      color={fg_color}
                    />
                    </Popover>
                    <Select
                      options={[
                        {value:'left',label:'Left'},
                        {value:'center',label:'Center'},
                        {value:'right',label:'Right'},
                      ]}
                      label="Text align"
                      onChange={(value) => this.setState({saleclock_text_align:value,clockSaveChanges:true})}
                      value={saleclock_text_align}
                    >
                    </Select>
                  </Stack>
                </Stack.Item>
              </Stack>
              <br></br>
              <Stack wrap={false} alignment="leading" spacing="tight">
                <Stack.Item fill>
                  <Stack distribution="fill" spacing="tight">
                    <RangeSlider
                      label="Font size"
                      labelHidden={true}
                      value={saleClockFontSize}
                      onChange={(value)=>{this.setState({saleClockFontSize:value,clockSaveChanges:true})}}
                      min='8'
                      max='36'
                      step='2'
                      output={true}
                      prefix="Font size"
                    ></RangeSlider>
                    <RangeSlider
                      label="Border radius"
                      value={saleClockBorderRadius}
                      labelHidden={true}
                      onChange={(value)=>{this.setState({saleClockBorderRadius:value,clockSaveChanges:true})}}
                      min='0'
                      max='50'
                      step='1'
                      output={true}
                      prefix="Border radius"
                    ></RangeSlider>
                  </Stack>
                </Stack.Item>
              </Stack>
            </div>
            <h2>Preview:</h2>
            <div style={previewStyle}>
              {
                sale_clock_text1 || 'Sale ends in'
              }
              &nbsp;
              {
                sale_clock_type === 1 || sale_clock_type === 8 || sale_clock_type === 9 ?
                <span id="count_down_clock">
                {this.countDownClock(new Date('2019-04-27 12:00:00'),'count_down_clock')}
                </span>
                :null
              }
              {
                saleClockOptions.map((option,i) => {
                  return <span key={i}>
                  {
                    sale_clock_type === 1 || sale_clock_type === 8 || sale_clock_type === 9 ?null:
                    option.value === sale_clock_type ?
                    (option.label).replace("(Count down clock)","")
                    :''
                  }
                  </span>;
                })
              }
              {
                sale_clock_text2
              }
            </div>
          </div>
          :null
        }
        </Stack>
        <PageActions
          secondaryActions={{content:"Reset to default",onAction:()=>{this.setSaleClockToDefault()}}}
          primaryAction={{content: 'Save', disabled:!clockSaveChanges, onAction: ()=>{this.saveClockType()}}}
        ></PageActions>
      </div>;
      
      let logincustomization_signup_link_splitted = this.state.logincustomization_signup_link.split('/');
      let logincustomization_signup_link = logincustomization_signup_link_splitted.length > 0 ? logincustomization_signup_link_splitted[logincustomization_signup_link_splitted.length-1] : logincustomization_signup_link_splitted;
      let section_loginCustomization = <div>
        <Card 
          title={
            <Stack spacing="tight" alignment="center">
              <Heading>Login page customization</Heading>
              <Badge>
                <Link plain url="https://support.digitalcoo.com/hc/en-us/articles/7285124543380" external>Learn more</Link>
              </Badge>
            </Stack>
          }
        >
          <Form onSubmit={this.handleLoginCustomizationSubmit}>
            <Card.Section>
            <Stack vertical>
              <RadioButton
                label="Do not add Wholesale Signup form link on login page."
                checked={this.state.logincustomization_signup_link_type === 'none'}
                name="logincustomization_signup_link_type"
                onChange={(checked, id) => {
                  let value = "none";
                  this.setState({logincustomization_signup_link_type: value});
                }}
              />
              <RadioButton 
                label="Disable default Shopify Signup form and add wholesale signup form link on login page."
                checked={this.state.logincustomization_signup_link_type === 'replace'}
                name="logincustomization_signup_link_type"
                onChange={(checked, id) => {
                  let value = "replace";
                  this.setState({logincustomization_signup_link_type: value});
                }}
              />

              {
                  this.state.logincustomization_signup_link_type === 'replace' &&
                  <div className="list-bordered-item" style={{marginLeft: "30px"}}>
                    <Stack vertical spacing="tight">
                      <FormLayout>
                        <TextField
                          autoComplete={false}
                          type="text"
                          prefix={ (this.props.shopDomain ? `https://${this.props.shopDomain}` : "") +"/pages/"}
                          label="Enter Wholesale Signup Form link"
                          placeholder="e.g register"
                          value={logincustomization_signup_link}
                          onChange={(v) => {
                            this.setState({logincustomization_signup_link: v});
                          }}
                        />
                        <TextField
                          autoComplete={false}
                          type="text"
                          label="Wholesale Signup form link label"
                          placeholder="e.g register"
                          value={this.state.logincustomization_signup_link_label}
                          onChange={(v) => {
                            this.setState({logincustomization_signup_link_label: v});
                          }}
                        />

                    </FormLayout>
                    </Stack>
                  </div>
                }


              <RadioButton
                label="Add wholesale Signup page link after the default Shopify signup page link on login page. ."
                checked={this.state.logincustomization_signup_link_type === 'append'}
                name="logincustomization_signup_link_type"
                onChange={(checked, id) => {
                  let value = "append";
                  this.setState({logincustomization_signup_link_type: value});
                }}
              />
          {
                  this.state.logincustomization_signup_link_type === 'append' &&
                  <div className="list-bordered-item" style={{marginLeft: "30px"}}>
                    <Stack vertical spacing="tight">
                      <FormLayout>
                        <TextField
                          autoComplete={false}
                          type="text"
                          prefix={ (this.props.shopDomain ? `https://${this.props.shopDomain}` : "") +"/pages/"}
                          label="Enter Wholesale Signup Form link"
                          placeholder="e.g register"
                          value={logincustomization_signup_link}
                          onChange={(v) => {
                            this.setState({logincustomization_signup_link: v});
                          }}
                        />
                        <TextField
                          autoComplete={false}
                          type="text"
                          label="Wholesale Signup form link label"
                          placeholder="e.g register"
                          value={this.state.logincustomization_signup_link_label}
                          onChange={(v) => {
                            this.setState({logincustomization_signup_link_label: v});
                          }}
                        />

                    </FormLayout>
                    </Stack>
                  </div>
                }
              </Stack>
            </Card.Section>
            <Card.Section>
              <Button submit primary loading={this.state.savingLoginCustomization} disabled={this.state.savingLoginCustomization}>Save</Button>
            </Card.Section>
          </Form>
        </Card>
      </div>
    
    // For generating prefixes and placeholders
    const zeroCurrency = Common.getCurrency(0, money_format);

    let section_additionalFee = <Card 
      title={
        <Stack spacing="tight" alignment="center">
          <Heading>Charge additional fee for wholesale orders</Heading> 
          <Badge>
            <Link plain url="https://support.digitalcoo.com/hc/en-us/articles/7485960581524" external>Learn more</Link>
          </Badge>
        </Stack>
      }
      
    >
      <Form onSubmit={this.handleAdditionalFeeSubmit}>

        <Card.Section>
          <Stack vertical spacing="extraLoose">
            <Checkbox
              label={`Enable additional fee`}
              value={this.state.additionalFee_enabled}
              checked={this.state.additionalFee_enabled}
              onChange={(value) => {
                this.setState({
                  additionalFee_enabled: value
                });
              }}
              name="enable_additional_fee"
            />

            {this.state.additionalFee_enabled && 
              <FormLayout>

                <TextField
                  label="Label"
                  value={this.state.additionalFee_label}
                  autoComplete={false}
                  onChange={(value)=>{
                    this.setState({additionalFee_label:value});
                  }}
                />

                <ChoiceList
                  title="Additional fee type"
                  choices={[
                    {
                      label: "Fixed amount",
                      value: "fixed",
                      renderChildren: (isSelected) => (
                        isSelected && (
                          <FormLayout>
                            <FormLayout.Group condensed>
                              <TextField
                                label="Amount"
                                labelHidden
                                prefix={zeroCurrency.symbol}
                                value={this.state.additionalFee_value}
                                autoComplete="off"
                                onChange={(value)=>{
                                  this.setState({additionalFee_value:value});
                                }}
                                onBlur={()=>{
                                  let value = Common.getCurrency(this.state.additionalFee_value, money_format).amount;
                                  
                                  this.setState({additionalFee_value:value.toString()});
                                }}
                              />
                              <div></div>
                              <div></div>
                            </FormLayout.Group>
                          </FormLayout>
                        )
                      )
                    },
                    {
                      label: "Percentage of cart subtotal",
                      value: "percentage",
                      renderChildren: (isSelected) => (
                        isSelected && (
                          <FormLayout>
                            <FormLayout.Group>
                            <TextField
                              label="Amount"
                              labelHidden
                              suffix="%"
                              value={this.state.additionalFee_value}
                              autoComplete="off"
                              onChange={(value)=>{
                                this.setState({additionalFee_value:value});
                              }}
                              onBlur={()=>{
                                let value = this.state.additionalFee_value;
                                value = parseFloat(value)||0;
                    
                                this.setState({additionalFee_value:value.toString()});
                              }}
                            />
                            <div></div>
                            <div></div>
                            </FormLayout.Group>
                          </FormLayout>
                        )
                      )
                    },
                  ]}
                  selected={this.state.additionalFee_value_type}
                  onChange={(value) => {
                    value = typeof value === "object" ? value[0] : value;
                    this.setState({
                      additionalFee_value_type: value
                    });
                  }}
                />

              </FormLayout>
            
            }
          </Stack>
        </Card.Section>

        <Card.Section>
          <Button submit primary loading={this.state.savingAdditionalFee} disabled={this.state.savingAdditionalFee}>Save</Button>
        </Card.Section>
      </Form>
    </Card>
      
      var toast = this.state.showToast? (
        <ToastMessage
        onDismiss={this.dissmissToast}
        message={this.state.toastMsg}
        error={this.state.toastError}
        />
      ): (null);

      var render_section = <div>{section_appEnableDisable}</div>;

      switch (this.props.selected_tab) {
          case 1:
            render_section = <div>
              <Stack vertical>
                {section_priceControl}
              </Stack>
            </div>;
          break;

          case 2:
            render_section = <div>{coupon_code_field_section}</div>;
          break;

          case 3:
            render_section = <div>{section_discountMethod}</div>;
          break;

          case 4:
            render_section = <div>{section_salesClock}</div>;
            break;

            case 5:
            render_section = <div>{section_loginCustomization}</div>;
          break;

          case 6:
            render_section = <div>{section_additionalFee}</div>;
            break;
        default:
          break;
      }

      if(!this.state.page_loaded){
        return (
          <>
          <div className="snipper-container">
             <center><Spinner color="teal" size="large" /></center>
          </div>
          {toast}
          </>
        );
      }
      else{
        return (
          <div>
            <TitleBar
              title="Settings"
              breadcrumbs={[
                { content: 'Dashboard', url: BUILD_VERSION_EXTENSION+'/dashboard', target: 'APP' }
              ]}
            />
            <div className="ws-general_settings__v238">
              {render_section}
            </div>
            {toast}
          </div>
        );
      }
    }
}