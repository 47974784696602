import React, { Component } from 'react';
import axios from 'axios';
import { Layout, Card, DataTable } from '@shopify/polaris';
import ToastMessage from '../Common/ToastMessage'

export default class ShopInfo extends Component{
   constructor(props){
      super(props);
      this.state = {
         shopDetail: {},
         page_loaded: false,
         showToast: false,
         toastMsg: "",
         toastError: false,
      };
   }

   componentDidMount(){
      axios.defaults.baseURL = 'https://wholesaleheavyload.digitalcoo.com';
      axios.defaults.headers.common['X-Ws-Shop-Domain'] = 'https://'+this.props.shop;
      axios.defaults.headers.common['Xws-Domain'] = this.props.shop;
      axios.get("/adminpanel/v1?shop=" + this.props.shop).then(response => {
         if(response && response.data){
            this.setState({
               shopDetail: response.data,
               page_loaded: true
            });
         }
         else{
            this.toastMessage("Server error", true);
         }
      }).catch(error => {
         this.toastMessage("Server error", true);
      });
   }

   click_to_copy = (event) => {
      try {
         var v = event.target.innerHTML;
         if (v) {
            copyValue(v);
            this.toastMessage("Copied to clipboard");
         } else {
            this.toastMessage("Could not copy data", true);
         }
      } catch (e) {
         this.toastMessage("Could not copy data", true);
      }
   }

   componentDidUpdate(){
      var a = document.querySelectorAll(".shop-detail-value");
      a.forEach( item => {
         item.removeEventListener("click", this.click_to_copy);
         item.addEventListener("click", this.click_to_copy);
      });
   }

   componentWillUnmount() {
      var a = document.querySelectorAll(".shop-detail-value");
      a.forEach( item => {
         item.removeEventListener("click", this.click_to_copy);
      });
   }

   toastMessage(msg, error) {
      this.setState({
         showToast: true,
         toastMsg: msg,
         toastError: error || false,
      });
   }

   dissmissToast = () => {
      this.setState({
         showToast: false,
      });
   }

   render(){
      var { shopDetail } = this.state;
      var table1Rows = [];
      var table2Rows = [];
      var table3Rows = [];
      var table4Rows = [];
      if(shopDetail && shopDetail.shop){
         table1Rows = [
            [
               <div className="shop-detail-key">Password protected</div>,
               <div className="shop-detail-value" title="Click to copy">{shopDetail.shop.password_enabled?"Yes":"No"}</div>
            ],
            [
               <div className="shop-detail-key">Shop name</div>,
               <div className="shop-detail-value" title="Click to copy">{shopDetail.shop.name}</div>
            ],
            [
               <div className="shop-detail-key">Shopify domain</div>,
               <div className="shop-detail-value" title="Click to copy">{shopDetail.shop.myshopify_domain}</div>
            ],
            [
               <div className="shop-detail-key">Domain</div>,
               <div className="shop-detail-value" title="Click to copy">{shopDetail.shop.domain}</div>
            ],
            [
               <div className="shop-detail-key">Owner name</div>,
               <div className="shop-detail-value" title="Click to copy">{shopDetail.shop.shop_owner}</div>
            ],
            [
               <div className="shop-detail-key">Customer email</div>,
               <div className="shop-detail-value" title="Click to copy">{shopDetail.shop.customer_email}</div>
            ],
            [
               <div className="shop-detail-key">Email</div>,
               <div className="shop-detail-value" title="Click to copy">{shopDetail.shop.email}</div>
            ],
            [
               <div className="shop-detail-key">Total products</div>,
               <div className="shop-detail-value" title="Click to copy">{shopDetail.products_count}</div>
            ],
            [
               <div className="shop-detail-key">Total collections</div>,
               <div className="shop-detail-value" title="Click to copy">{shopDetail.collections_count}</div>
            ]
         ]
      }
      if(shopDetail && shopDetail.theme){
         var theme = shopDetail.theme;
         table2Rows = [
            [
               <div className="shop-detail-key">Theme ID</div>,
               <div className="shop-detail-value" title="Click to copy">{theme.id}</div>
            ],
            [
               <div className="shop-detail-key">Theme title</div>,
               <div className="shop-detail-value" title="Click to copy">{theme.title}</div>
            ],
            [
               <div className="shop-detail-key">Original theme name</div>,
               <div className="shop-detail-value" title="Click to copy">{theme.theme_name}</div>
            ],
            [
               <div className="shop-detail-key">File: wholesale-all-in-one.liquid</div>,
               <div className="shop-detail-value" title="Click to copy">{theme.files["wholesale-all-in-one.liquid"]?"Exists":"Not exists"}</div>
            ]
         ]
      }
      if(shopDetail && shopDetail.customers){
         var customers = shopDetail.customers;
         table3Rows = [
            [
               <div className="shop-detail-key">Total customers</div>,
               <div className="shop-detail-value" title="Click to copy">{customers.count}</div>
            ],
            [
               <div className="shop-detail-key">Last 7 days customers</div>,
               <div className="shop-detail-value" title="Click to copy">{customers.last_7_days_count}</div>
            ],
            [
               <div className="shop-detail-key">Last 30 days customers</div>,
               <div className="shop-detail-value" title="Click to copy">{customers.last_30_days_count}</div>
            ]
         ]
      }
      if(shopDetail && shopDetail.customers){
         var payment = shopDetail.payment;
         table4Rows = [
            [
               <div className="shop-detail-key">Plan ID</div>,
               <div className="shop-detail-value" title="Click to copy">{payment.plan_id}</div>
            ],
            [
               <div className="shop-detail-key">Plan Name</div>,
               <div className="shop-detail-value" title="Click to copy">{payment.plan_name}</div>
            ]
         ]
      }
      var toast = this.state.showToast? (
         <ToastMessage
           onDismiss={this.dissmissToast}
           message={this.state.toastMsg}
           error={this.state.toastError}
         />
      ): (null);
      return(
         <div>
            {toast}
            {
               this.state.page_loaded && 
               <div className="ws--shop_info">
                  <Layout>
                     <Layout.Section oneHalf>
                        <Card title="Shop Information">
                           <DataTable
                              columnContentTypes={[
                                 'text',
                                 'text',
                              ]}
                              headings={[
                                 'Key',
                                 'Value',
                              ]}
                              rows={table1Rows}
                           />
                        </Card>
                        <Card title="Customers Information">
                           <DataTable
                              columnContentTypes={[
                                 'text',
                                 'text',
                              ]}
                              headings={[
                                 'Key',
                                 'Value',
                              ]}
                              rows={table3Rows}
                           />
                        </Card>
                     </Layout.Section>
                     <Layout.Section oneHalf>
                        <Card title="Theme Information">
                           <DataTable
                              columnContentTypes={[
                                 'text',
                                 'text',
                              ]}
                              headings={[
                                 'Key',
                                 'Value',
                              ]}
                              rows={table2Rows}
                           />
                        </Card>
                        <Card title="Payment Information">
                           <DataTable
                              columnContentTypes={[
                                 'text',
                                 'text',
                              ]}
                              headings={[
                                 'Key',
                                 'Value',
                              ]}
                              rows={table4Rows}
                           />
                        </Card>
                     </Layout.Section>
                  </Layout>
               </div>
            }
         </div>
      );
   }
}


function copyValue(value) {
   try {
      var tempInput = document.createElement("input");
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
   } catch (e) {
   }
}
