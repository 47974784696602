import React, { Component } from 'react';
import {Layout,Stack} from '@shopify/polaris';
import store from 'store2';

export default class UpdateVariantsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      variants: store('variants') || [],
      product_deleted: false
    }
  }
  deleteProduct(item){
    this.setState({
      variants: this.state.variants.filter(i => i.id !== item.id),
      product_deleted: true
    });
  }
  createUIForVariants(variant){
    return <ul><li><strong>{variant.title}</strong></li></ul>;
  }
  render() {
    if(this.state.product_deleted){
      store('variants',this.state.variants);
    }
    let variants = this.state.variants.map((variant,i) => {
     return <ul key={i} className="selected-products">
      <li className="selected-products-list">
        <span className="float-left">
          {
            variant.product.length > 0?
            <span>
              { variant.product[0].title }
              <a href={'https://'+this.props.shop+'/products/'+variant.product[0].handle+'?variant='+variant.id} target="_blank" rel="noopener noreferrer">
              &nbsp;[View]
              </a>
              <a href={'https://'+this.props.shop+'/admin/products/'+variant.product[0].id+'/variants/'+variant.id} target="_blank" rel="noopener noreferrer">
              &nbsp;[Edit]
              </a>
            </span>
            :'null'
          }
          &nbsp;
          {this.createUIForVariants(variant)}
        </span>
        <button className="float-right btn-remove" onClick={this.deleteProduct.bind(this, variant)}>&#9747;</button>
        <div className="clearfix"></div>
      </li>
     </ul>
    });
    // let variants = this.state.variants.map((item,i) => {
    //   return <div key={i}>
    //     <ul className="selected-products">
    //       <li className="selected-products-list">
    //         <span className="float-left">{item.title}</span>
    //         <button className="float-right btn-remove" onClick={this.deleteProduct.bind(this, item)}>&#9747;</button>
    //         <div className="clearfix"></div>
    //       </li>
    //     </ul>
    //   </div> 
    // });
    return (
      <Layout>
        <Layout.Section>
          <Stack
            vertical={true}
            spacing="extraTight"
            distribution="equalSpacing"
          >
          {variants}
          </Stack>
        </Layout.Section>
      </Layout>
    );
  }
}