import React, { Component } from 'react';
import { Layout, Card, Stack, Heading, TextStyle, Collapsible, Button, List} from '@shopify/polaris';
import FooterHelpLinks from './FooterHelpLinks';
import {LinkOverride} from '../Helpers/LinkOverride';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      installSection: false,
      uninstallSection: false,
    }
    if (!this.props.shop) {
      this.props.history.push('/install');
    }
  }

  render() {
    const {uninstallSection, installSection} = this.state;
    const installationSection = <div>
      <Stack vertical spacing="baseTight">/
        <Stack vertical spacing="tight">
          <TextStyle>These installation instructions are only applicable for free themes. Below is the list of free themes:</TextStyle>
          <List>
            <List.Item>Dawn, Sense, Refresh, Craft, Ride, Spotlight, Taste, Crave, Origin, Publisher, Colorblock, Studio</List.Item>
          </List>
          <span style={{fontWeight: '600'}}><TextStyle>Note, if your theme name is not in this list then please <span style={{textDecoration: 'underline'}}><LinkOverride external href={BUILD_VERSION_EXTENSION+"/contact-us"}>contact us</LinkOverride></span>, and our team will integrate Wholesale All In One into your website.</TextStyle></span>
        </Stack>

        <Stack vertical spacing = "loose">
          <Stack vertical spacing="tight">
            <Heading>App activation guide for free themes</Heading>
            <TextStyle>If you are using a free theme for your Shopify store and want to install the Wholesale All-in-One application, we have provided step-by-step instructions to help you with the installation process. By following these instructions, you can easily activate the Wholesale app and start benefiting from its features.</TextStyle>
          </Stack>
          <Stack vertical spacing ="tight">
            <Heading>Step 1</Heading>
            <TextStyle>- Log in to your Shopify admin account.</TextStyle>
            <TextStyle>- Click on the "Online Store" > "themes' option located in the sidebar.</TextStyle>
            <TextStyle>- On the right side of the Online Store page, locate and click the "Customize" button.</TextStyle>
            <img src ="/images/installations_uninstallations-customize-theme.png" alt='' style={{ maxWidth: '800px', width: 'auto' }} ></img>
          </Stack>
          <Stack vertical spacing ="tight">
            <Heading>Step 2</Heading>
            <TextStyle>- Once you are in the Shopify Customization section, Click the "App embeds" icon.</TextStyle>
            <img src ="/images/installations_uninstallations-app-embed-icon.png" alt='' style={{ maxWidth: '800px', width: 'auto' }} ></img>
          </Stack>
          <Stack vertical spacing ="tight">
            <Heading>Step 3</Heading>
            <TextStyle>- Within the App embeds section, you will find the Wholesale All-in-One application listed.</TextStyle>
            <TextStyle>- Activate the checkbox by clicking on it, indicating your intent to install and use the Wholesale app.</TextStyle>
            <img src ="/images/installations_uninstallations-enable-app.png" alt='' style={{ maxWidth: '800px', width: 'auto' }} ></img>
          </Stack>
          <Stack vertical spacing ="tight">
            <Heading>Step 4</Heading>
            <TextStyle>After activating the Wholesale All-in-One application, click on the "Save" button to apply the changes made to your theme.
</TextStyle>
            <img src ="/images/installations_uninstallations-save.png" alt='' style={{ maxWidth: '800px', width: 'auto' }} ></img>
          </Stack>
          <Stack vertical spacing ="tight">
            <TextStyle>You're Done.</TextStyle>
          </Stack>

        </Stack>
      </Stack>
    </div>

    const uninstallationSection = <div>
        <Stack vertical spacing="loose">
          <Stack vertical spacing="tight">
            <Heading>Introduction:</Heading>
            <TextStyle>If you no longer require the Wholesale All-in-One application in your Shopify store and wish to remove it from your app list, follow the steps outlined below. This article will guide you through the process of uninstalling the Wholesale All-in-One application.</TextStyle>
          </Stack>
          <Stack vertical spacing="tight">
            <Heading>Step 1: Open Theme Settings</Heading>
            <TextStyle>- Log in to your Shopify admin dashboard</TextStyle>
            <TextStyle>- Navigate to the <b>"Settings"</b> option in the sidebar menu and click on it.</TextStyle>
            <div>
              <img src="/images/installations_uninstallations-open-settings.png" alt='' style={{ maxWidth: '800px', width: 'auto' }} />
            </div>
          </Stack>
          <Stack vertical spacing="tight">
            <Heading>Step 2: Open Apps and Sales Channels</Heading>
            <TextStyle>- Within the Settings page, locate and select the <b>"Apps and sales channels"</b> option.</TextStyle>
            <div>
              <img src="/images/installations_uninstallations-apps-and-sales-channels.png" alt='' style={{ maxWidth: '800px', width: 'auto' }} />
            </div>
          </Stack>
          <Stack vertical spacing="tight">
            <Heading>Step 3: Click on Uninstall</Heading>
            <TextStyle>- On the right side of the Apps and sales channels page, you will find a list of installed applications.</TextStyle>
            <TextStyle>- Scroll down or search for the Wholesale All-in-One application in the list.</TextStyle>
            <TextStyle>- Locate the Uninstall option parallel to the Wholesale All-in-One application listing.</TextStyle>
            <TextStyle>- Click on the <b>Uninstall</b> option to initiate the removal process.</TextStyle>
            <div>
              <img src="/images/installations_uninstallations-uninstall-app.png" alt='' style={{ maxWidth: '800px', width: 'auto' }} />
            </div>
          </Stack>
          <TextStyle>That's all.</TextStyle>
        </Stack>

    </div>

    return (
      <div>
        <div style={{ marginBottom: '2rem', marginTop: '2rem' }}>
          <Layout>
            <Layout.Section>
              <Card>
                <Card.Section>
                  <Stack vertical spacing="tight">
                    <div>
                      <Stack>
                        <Stack.Item>
                            <Heading>How to activate Wholesale All In One app on your website?</Heading>
                        </Stack.Item>
                        <Stack.Item>
                            <Button size="slim"
                                onClick={() => {this.setState({installSection: !this.state.installSection})}}
                            >{installSection?"Hide Instructions":"View Instructions"}</Button>
                            </Stack.Item>
                      </Stack>
                    </div>
                    <div>
                      <Collapsible 
                      open={this.state.installSection} 
                      id="basic-collapsible"
                      transition={{duration: '250ms', timingFunction: 'ease'}}
                      >
                        {installationSection}
                      </Collapsible>
                    </div>
                  </Stack>
                </Card.Section>
              </Card>
            </Layout.Section>
            
            <Layout.Section>
              <Card>
                <Card.Section>
                  <Stack vertical spacing="tight">
                    <div>
                      <Stack>
                        <Stack.Item>
                            <Heading>How to uninstall / delete Wholesale All In One from website?</Heading>
                        </Stack.Item>
                        <Stack.Item>
                            <Button size="slim"
                                onClick={() => {this.setState({uninstallSection: !this.state.uninstallSection})}}
                            >{uninstallSection?"Hide Instructions":"View Instructions"}</Button>
                            </Stack.Item>
                      </Stack>
                    </div>
                    <div>
                      <Collapsible 
                      open={this.state.uninstallSection} 
                      id="basic-collapsible"
                      transition={{duration: '250ms', timingFunction: 'ease'}}
                      >
                        {uninstallationSection}
                      </Collapsible>
                    </div>
                  </Stack>
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
        </div>
        
        <FooterHelpLinks help={this.props.help} />
      </div>
    );

  }

}