import React from 'react';
import { Card,Layout,ResourceItem,TextStyle,ResourceList,Stack,Button } from '@shopify/polaris';
import axios from '../Axios';
import CustomersFAQ from './CustomersFAQ';
import PageTitleBar from '../Common/TitleBar';
import ToastMessage from '../Common/ToastMessage';
import CustomerList from './CustomersList';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class ManageCustomerDashboard extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            selected_id: this.props.match?this.props.match.params?this.props.match.params.id?(this.props.match.params.id):("wholesale_approval"):("wholesale_approval"):("wholesale_approval"),
            showToast: false,
            toastMsg: '',
            toastError: false,
            pending_customer_count: 0,
            rejected_customer_count: 0
        }
        axios.defaults.headers.common['X-Ws-Shop-Domain'] = "https://" + this.props.shop;
    }

    componentDidMount() {
        this.getCount("pending");
        this.getCount("rejected");
    }

    getCount(status) {
        axios.get('/customer/api/335/admin/count?shop=' + this.props.shop + "&status=" + status).then(response => {
            if (response && response.data && typeof response.data.count !== "undefined") {
                if (status === "pending") {
                    this.setState({
                        pending_customer_count: response.data.count
                    });
                } else if (status === "rejected") {
                    this.setState({
                        rejected_customer_count: response.data.count
                    });
                }
            }
        }).catch(e => {
            this.flashMessage("Server error", true)
        });
    }
    showToastMessage(msg, error) {
        this.setState({
            showToast: true,
            toastMsg: msg,
            toastError: error || false,
        });
    }

    flashMessage(msg, isError) {
        this.showToastMessage(msg, isError);
    }

    dissmissToast = () => {
        this.setState({
            showToast: false,
        });
    }

    redirectShopifyCustomerList(query){
        window.open("https://"+this.props.shop+"/admin/customers"+query)
    }

    render(){
        const pending_content = "Pending Approval ("+this.state.pending_customer_count+")";
        const rejected_content = "Rejected Customers ("+this.state.rejected_customer_count+")";
        const tabs = [{
                id: 'wholesale_approval',
                content: pending_content,
                url: '#',
            },
            {
                id: 'rejected_customers',
                content: rejected_content,
                url: '#',
            },
            {
                id: 'approved_customer',
                content: 'Approved Customers',
                url: '#',
            },
            {
                id: 'faq',
                content: 'Quick Answers',
                url: '#',
            }
        ];
        var toast = this.state.showToast ? 
        (<ToastMessage
            onDismiss={this.dissmissToast}
            message={this.state.toastMsg}
            error={this.state.toastError}
        />)
        :(null);
        return(
            <div className="admin_area_waio">
                {toast}
                {
                    this.props.no_title ? null :
                    <PageTitleBar
                        breadcrumbs={[{content: 'Manage Customers', url: BUILD_VERSION_EXTENSION+'/ws-customers', target: 'APP'}]}
                        title = "Customers Lists"
                        secondaryActions = {
                            [{
                                content: "Back",
                                url: BUILD_VERSION_EXTENSION+"/ws-customers"
                            }]
                        }
                    />
                }
                <div>
                    <Layout>
                        <Layout.Section>
                            <Card>
                                <div className="admin_panel_settings">
                                    <div className="admin_leftSide wsaio-leftadmin-manage-customer2021">
                                        <Card.Section>
                                          <ResourceList
                                              items={tabs}
                                              renderItem={(item) => {
                                              const {id, content} = item;   
                                              return (
                                                  <div className={"grid_items customers-list-tabs-2021-02"}>
                                                      <ResourceItem
                                                      id={(this.state.selected_id===id ? "selected_tab":id)}
                                                      onClick={()=>{
                                                          this.setState({selected_id:id});
                                                      }}
                                                      >
                                                      <TextStyle variation="strong">{content}</TextStyle>
                                                      </ResourceItem>
                                                  </div>
                                              );
                                              }}
                                          />
                                        </Card.Section>
                                    </div>
                                    <div id="admin_righSide">
                                      <Card.Section>
                                        {this.state.selected_id === "wholesale_approval" && 
                                            <div className="grid_tabs ws-forms-2021">
                                                <CustomerList {...this.props} selected_tab={0} status="pending"/>
                                            </div>
                                        }
                                        {(this.state.selected_id === "approved_customer" || this.state.selected_id === "approve_active_inactive_customers" || this.state.selected_id === "approved_active" || this.state.selected_id === "approved_inactive" ) && 
                                            <div className={"grid_tabs ws-forms-2021 "+(this.state.selected_id)}>
                                            <div className={"Polaris-Card__Section approved_customer_grid"} id={this.state.selected_id === "approve_active_inactive_customers" && "selected_grid"}>
                                                <Stack>
                                                    <Stack.Item fill>
                                                        <p><b>Approved Customers [Active & Inactive]</b></p>
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                    <Button
                                                        onClick={() => {
                                                            const segmentQuery = "customer_tags CONTAINS 'VerifiedByWholesaleAllInOne'";
                                                            this.redirectShopifyCustomerList("?segment_query="+segmentQuery);
                                                        }}
                                                        primary
                                                        size="slim">View</Button>
                                                    </Stack.Item>
                                                </Stack>
                                                <p>List of all customers approved via Wholesale All In One application.</p>
                                            </div>
                                            
                                            <div className={"Polaris-Card__Section approved_customer_grid"} id={this.state.selected_id === "approved_active" && "selected_grid"}>
                                                <Stack>
                                                    <Stack.Item fill>
                                                        <p><b>Approved Customers [Active only]</b></p>
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                       <Button
                                                        onClick={() => {
                                                            const segmentQuery = "customer_account_status = 'ENABLED' AND customer_tags CONTAINS 'VerifiedByWholesaleAllInOne'";
                                                            this.redirectShopifyCustomerList("?segment_query="+segmentQuery);
                                                        }}
                                                        primary size="slim">View</Button>
                                                    </Stack.Item>
                                                </Stack>
                                                <p>List of all customers approved via Wholesale All In One application and all customer in this list have activated their accounts.</p>
                                            </div>
                                            
                                            <div className={"Polaris-Card__Section approved_customer_grid"} id={this.state.selected_id === "approved_inactive" && "selected_grid"}>
                                                <Stack>
                                                    <Stack.Item fill>
                                                        <p><b>Approved Customers [Inactive only]</b></p>
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <Button
                                                        onClick={() => {
                                                            const segmentQuery = "customer_account_status != 'ENABLED' AND customer_tags CONTAINS 'VerifiedByWholesaleAllInOne'";
                                                            this.redirectShopifyCustomerList("?segment_query="+segmentQuery);
                                                        }}
                                                        primary size="slim">View</Button>
                                                    </Stack.Item>
                                                </Stack>
                                                <p>List of all customers approved via Wholesale All In One application.<br />These customers received the account approval email from store owner but haven't activated their account yet, <a href="https://support.digitalcoo.com/hc/en-us/articles/360062402771-Customer-account-status" target='_blank' rel="noreferrer">Learn More</a></p>
                                            </div>
                                       </div>
                                        }
                                        {
                                            this.state.selected_id === "rejected_customers" && 
                                            <div className="grid_tabs ws-forms-2021">
                                                <CustomerList {...this.props} selected_tab={1} status="rejected" />
                                            </div>
                                        }
                                        {
                                            this.state.selected_id === "faq" && 
                                            <div className="grid_tabs ws-forms-2021">
                                                <CustomersFAQ />
                                            </div>
                                        }
                                      </Card.Section>
                                    </div>
                                </div>
                            </Card>
                        </Layout.Section>
                    </Layout>
                </div>
            </div>
        );
    }
}