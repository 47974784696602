import React from "react";
import { FooterHelp, Link } from "@shopify/polaris";

export default function FooterHelpLinks() {
  return (
    <div>
      <div className="clearfix"></div>
      <FooterHelp>
        How to Apply Discounts? Read <Link external={true} url="https://support.digitalcoo.com/hc/en-us/categories/360001780792">User Guide</Link>
      </FooterHelp>
    </div>
  );
}
