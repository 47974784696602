import React, { Component } from 'react';
import SkeletonPageComp from './SkeletonPageComp';
import ToastMessage from '../Common/ToastMessage';
import axios from '../Axios';
import NetTerms from './NetTerms';
import NetTerms1 from './NetTerms1';

export default class NetTermsLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      version: 0,
      page_loaded: false,

      netTerms: null,

      showToast: false,
      toastMsg: '',
      toastError: false
    }
  }

  showToastMessage(msg, error){
    this.setState({
      showToast: true,
      toastMsg: msg,
      toastError: error||false,
    });
  }
  
  dissmissToast = () =>{
    this.setState({
      showToast: false,
    });
  }

  componentDidMount(){
    this.getNetTerm();
  }

  getNetTerm(){
    axios.get('/app/get-net-term/' + this.props.shop)
      .then(response => {
        var version = 0; // default version will be loaded based on this
        if (response && response.data && response.data.status === 1) {
          if (response.data.netTerms) {
            var netTerm = response.data.netTerms;
            
            if(typeof netTerm.version !== "undefined") version = netTerm.version;
          }
        }

        this.setState({page_loaded:true, version: version, netTerms: response.data.netTerms});
      })
      .catch(error => {
        this.showToastMessage(error,true);
        this.setState({page_loaded:true});
      });
  }

  render() {
    const { version } = this.state;
   
    var toast = this.state.showToast? (
      <ToastMessage
        onDismiss={this.dissmissToast}
        message={this.state.toastMsg}
        error={this.state.toastError}
      />
    ): (null);


    if(this.state.page_loaded){
      return (
        <div>
          {version === 0 ?

            <NetTerms {...this.props} />
          
          : version === 1 ?
            <NetTerms1 {...this.props} netTerms={this.state.netTerms} />
          : null }

          {toast}
        </div>
      );
    }
    else{
      return (<SkeletonPageComp></SkeletonPageComp>);
    }
  }
}