import React from "react";


class PriceText extends React.Component {
    render() {
        return (
            <p style={{display: "flex", alignItems: "baseline", justifyContent: "center", fontWeight: "700", margin: "0"}}>
                <sup style={{color: "#828a91", fontWeight: "400", paddingRight: "0.25rem", fontSize: "1rem", alignSelf: "flex-start"}}>{this.props.currency}</sup>
                <span style={{fontSize: "4rem", color: "#202326", lineHeight: "4rem", padding: "0 6.4px", margin: "0", display: "inline-flex", fontKerning:"normal",  fontVariantLigatures: "common-ligatures contextual discretionary-ligatures", fontFeatureSettings: "'kern', 'liga', 'clig', 'calt', 'dlig'", fontWeight: "750", letterSpacing: "-0.0207142857em"}}>{this.props.symbol}{this.props.price}</span>
                <sub style={{ "color": "#828a91", "fontSize": "1.125rem", "lineHeight": "0.25rem", "fontWeight": "400", "letterSpacing": "normal", "paddingBottom": "0.75rem", "alignSelf": "flex-end" }}>{this.props.inverval_text}</sub>
            </p>
        );
    }
}

export default PriceText;