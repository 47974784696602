import React, { Component } from 'react';
import {Layout,Stack} from '@shopify/polaris';
import store from 'store2';

export default class UpdateExcludedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: store('excludedProducts') || [],
      product_deleted: false
    }
  }
  deleteProduct(item){
    this.setState({
      products: this.state.products.filter(i => i.id !== item.id),
      product_deleted: true
    });
  }
  render() {
    if(this.state.product_deleted){
      store('excludedProducts', this.state.products);
    }
    let products = this.state.products.map((item,i) => {
      return <div key={i}>
        <ul className="selected-products">
          <li className="selected-products-list">
            <span className="float-left">
            {item.title}
            {
              item.handle?
              <span>
              <a href={'https://'+this.props.shop+'/products/'+item.handle} target="_blank" rel="noopener noreferrer">
              &nbsp;[View]
              </a>
              <a href={'https://'+this.props.shop+'/admin/products/'+item.id} target="_blank" rel="noopener noreferrer">
              &nbsp;[Edit]
              </a>
              </span>
              :''
            }
            </span>
            <button className="float-right btn-remove" onClick={this.deleteProduct.bind(this, item)}>&#9747;</button>
            <div className="clearfix"></div>
          </li>
        </ul>
      </div> 
    });
    return (
      <Layout>
        <Layout.Section>
          <Stack
            vertical={true}
            spacing="extraTight"
            distribution="equalSpacing"
          >
          {products}
          </Stack>
        </Layout.Section>
      </Layout>
    );
  }
}