import React, { Component } from 'react';
import { TextField,Select,Stack,Spinner } from '@shopify/polaris';
import axios from '../Axios';
import store from 'store2';

export default class VariantPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shop_id:this.props.shop_id,
            shop:this.props.shop,
            search:'',
            all_products:[],
            selected_variants: store('variants') || [],
            selected_products: [],
            collections: [],
            collection_selected: '',
            collection_options: [],
            tem_product: [],
            fetching_collections: false,
            fetching_products: false
        };
    }
    componentDidMount(){
        this.fetch_collections();
    }
    fetch_collections(){
        this.setState({
            fetch_collections: true,
            fetch_products: false
        });
        axios.get('/api/get-all-collections/' + this.props.shop_id)
        .then( response => {
            let data = response.data;
            if(data.smart_collections.length > 0){
                let options = [];
                data.smart_collections.forEach(collection => {
                    options.push({
                        label: collection.title,
                        value: collection.id.toString(),
                        id: collection.id,
                        name: collection.title
                    })
                });
                let collection_id = data.smart_collections[this.getRandomInt(data.smart_collections.length)].id;
                this.fetch_products(collection_id,options);
            }
        })
        .catch(error => {
        });
    }
    fetch_products(collection_id,options){
        this.setState({
            fetch_collections: false,
            fetch_products: true
        });
        axios.get('/api/'+this.props.shop_id+'/get-collection-products/'+collection_id )
        .then( response => {
            let data = response.data;
            if(data.length < 1){
                this.fetch_collections();
            }else{
                let newPro = [];
                if(data.length > 0){
                    newPro = data;
                    this.setState({
                        all_products: newPro,
                        tem_product: newPro,
                        collection_selected: collection_id.toString(),
                        collection_options: options
                    })
                }
            }
        })
        .catch(error => {
        });
    }
    getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }
    variantsUI(products){
        let variants = products.variants;
        let res;
        if(variants.length > 0){
            res = variants.map((variant,idx)=>{
                return <li key={idx} id='variant' data-id={variant.id}>
                <label className="Polaris-Choice" htmlFor={variant.title+variant.id}><span className="Polaris-Choice__Control"><span className="Polaris-Checkbox">
                    <input id={variant.title+variant.id} type="checkbox" className="Polaris-Checkbox__Input" aria-invalid="false" aria-checked="false" title={variant.title} name={variant.product_id} value={variant.id || ''} onChange={this.handleVariantSelected.bind(this, idx)}
                    checked={this.state.selected_variants.findIndex(x => (x.id).toString() === (variant.id).toString()) > -1 ?true:false}
                    ></input>
                    <span className="Polaris-Checkbox__Backdrop"></span><span className="Polaris-Checkbox__Icon">
                    <span className="Polaris-Icon"><svg className="Polaris-Icon__Svg" viewBox="0 0 20 20" focusable="false" aria-hidden="true"><path d="M8.315 13.859l-3.182-3.417a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.22 2.393 4.942-5.327a.437.437 0 0 1 .643 0l.643.684a.504.504 0 0 1 0 .683l-5.91 6.35a.437.437 0 0 1-.642 0"></path></svg></span></span></span></span><span className="Polaris-Choice__Label">{variant.title}  (${variant.price}) </span>
                </label> 
                </li>
            });
        }
        return <ul className="variant_ul">{res}</ul>;
    }
    handleChangeSearch = (value) => {
        if(value.trim() !== ''){
            this.setState({
                tem_product: this.state.all_products
            })
            let new_pro = this.state.all_products.filter(product => product.title.match(new RegExp(value, 'i')));
            this.setState({
                search: value,
                tem_product: new_pro
            });
        }
        else{
            this.setState({
                search: value,
                tem_product: this.state.all_products
            });
        }
    };
    handleCollectionSelected = (newValue) => {
        this.setState({
            collection_selected: newValue,
            tem_product: this.state.all_products
        });
        axios.get('/api/'+this.props.shop_id+'/get-collection-products/'+newValue )
        .then( response => {
            let data = response.data;
            let newProducts = [];
            if(data.length > 0){
                newProducts = data;
                if(this.state.search !== ''){
                    let new_pro = this.state.all_products.filter(product => product.title.match(new RegExp(this.state.search, 'i')));
                    this.setState({
                        all_products: newProducts,
                        tem_product: new_pro
                    })
                }
                else{
                    this.setState({
                        all_products: newProducts,
                        tem_product: newProducts
                    })
                }
            }
        })
        .catch(error => {
        });
    };
    handleVariantSelected(i,e){
        let product_array = [];
        let all_products = this.state.all_products;
        const { value, title, name} = e.target;
        let product_id = name;
        let index = this.state.selected_variants.findIndex(x => x.id===value);
        let p_index = all_products.findIndex(x=>parseInt(x.id,10) === parseInt(product_id,10));
        if( p_index > -1){
            product_array.push({
                id: all_products[p_index].id,
                title: all_products[p_index].title,
                handle:all_products[p_index].handle,
                image: all_products[p_index].image
            });
        }
        if(index === -1){
            this.setState({
                selected_variants: this.state.selected_variants.concat({ // concat returns a new array
                    id:value,
                    title: title,
                    product_id: product_id,
                    product: product_array
                })
            });
        }
        else{
            let new_selected_variants = this.state.selected_variants.filter(variant => variant.id !== value);
            this.setState({
                selected_variants: new_selected_variants
            });
        }
    }
    render() {
        if(this.state.selected_variants){
            store('variants', this.state.selected_variants);
        }
        let result_variants =  this.state.tem_product.map((product, i) => {
              return  <li key={i} id='product' className="variants-list-select-item"> 
                {/* <label className="Polaris-Choice" htmlFor={product.title+product.id}><span className="Polaris-Choice__Control"><span className="Polaris-Checkbox">
                    <input id={product.title+product.id} type="checkbox" className="Polaris-Checkbox__Input" aria-invalid="false" role="checkbox" aria-checked="false" title={product.title} name="product_id" value={product.id ||''} onChange={this.handleProductSelected.bind(this, i)}
                    ></input>
                    <span className="Polaris-Checkbox__Backdrop"></span><span className="Polaris-Checkbox__Icon">
                    <span className="Polaris-Icon"><svg className="Polaris-Icon__Svg" viewBox="0 0 20 20" focusable="false" aria-hidden="true"><path d="M8.315 13.859l-3.182-3.417a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.22 2.393 4.942-5.327a.437.437 0 0 1 .643 0l.643.684a.504.504 0 0 1 0 .683l-5.91 6.35a.437.437 0 0 1-.642 0"></path></svg></span></span></span></span><span className="Polaris-Choice__Label"><strong
                    >{product.title}</strong></span>
                </label> */}
                {/* <strong>{product.title} <a href={'https://'+this.props.shop+'/admin/products/'+product.id} target="_blank" rel="noopener noreferrer"></a></strong> */}
                    <strong>{product.title}</strong>
                    {this.variantsUI(product)}
                </li>;
            });
        if(this.state.all_products.length < 1){
            return(
                <div className="snipper-container">
                    <center><Spinner color="teal" size="large" /></center>
                    <br></br>
                    {this.state.fetch_collections?'Fetching collections...':''}
                    {this.state.fetch_products?'Fetching products...':''}
                </div>
            );
        }
        else{
            return (
                <div>
                    <Stack vertical spacing="extraTight">
                    {this.state.collection_options.length>0?
                        <Select
                            label={'Collection: '}
                            labelInline
                            options={this.state.collection_options}
                            onChange={this.handleCollectionSelected}
                            value={this.state.collection_selected}
                        />
                    :null}
                    <TextField
                        label=""
                        value={this.state.search}
                        autoComplete={false}
                        placeholder={'Search product by title'}
                        onChange={this.handleChangeSearch}
                    />
                    </Stack>
                    {this.state.selected_variants.length} variants selected
                    {/* {this.state.selected_products.length} products selected */}
                    <br></br>
                    <ul className="variants-ul">{result_variants}</ul>
                </div>
            );
        }
    }
  }