import React, { Component } from "react";
import { Card, Link } from "@shopify/polaris";

export default class FAQ extends Component {
  render() {
    return (
      <div>
        <Card
            title="Frequently Asked Questions"
        >
            <Card.Section>
            You can read post install frequently asked questions in our <Link url="https://support.digitalcoo.com/hc/en-us/articles/360054881891" external>  Knowledge Base [Post Install FAQ]</Link>.
            </Card.Section>
        </Card>
      </div>
    );
  }
}