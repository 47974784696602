import React, { Component } from 'react';
import {Layout,Stack} from '@shopify/polaris';
import store from 'store2';

export default class UpdateExcludedCollections extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      collections: store('excludedCollections') || [],
      product_deleted: false
    }
  }
  deleteProduct(item){
    this.setState({
      collections: this.state.collections.filter(i => i.id !== item.id),
      product_deleted: true
    });
  }

  render() {
    if(this.state.product_deleted){
      store('excludedCollections', this.state.collections);
    }
    let collections = this.state.collections.map((item,i) => {
      return <div key={i}>
        <ul className="selected-products">
          <li className="selected-products-list">
            <span className="float-left">{item.title}</span>
            <button className="float-right btn-remove" onClick={this.deleteProduct.bind(this, item)}>&#9747;</button>
            <div className="clearfix"></div>
          </li>
        </ul>
      </div> 
    });

    return (
      <Layout>
        <Layout.Section>
          <Stack
            vertical={true}
            spacing="extraTight"
            distribution="equalSpacing"
          >
          {collections}
          </Stack>
        </Layout.Section>
      </Layout>
    );
  }
}