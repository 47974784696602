import React, { Component } from 'react';
import axios from '../Axios';
import { Page, Button, Card, Form, FormLayout, TextField, InlineError } from '@shopify/polaris';


export default class Install extends Component {
  state = {
    url: "",
    loading: false
  };

  validation(){
    var url = this.state.url;
    if(url && url !== "" && url.trim() !== "" && url.indexOf(".myshopify.com") > -1){
      return true;
    }
    else{
      return false;
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const shops = {
      url: this.state.url
    }
    if (shops.url && shops.url !== "" && shops.url.indexOf(".myshopify.com") > -1) {
      let shopify_base_url = shops.url;
      try {
        shopify_base_url = shopify_base_url.replace("https://", "")
        .replace("http://", "")
        .replace(".myshopify.com/", ".myshopify.com");
      } catch (e) { }
      if (shopify_base_url) {
        this.setState({loading:true});
        axios.get('/shopapp?shop=' + shopify_base_url)
        .then(response => {
          if (response.data.redirect_uri && response.data.shop === shopify_base_url) {
            window.top.location.href = response.data.redirect_uri;
          }
        })
        .catch(error => {
          this.setState({ loading:false });
        });
      }
    }
  };
  handleChange = (field) => {
    return (value) => this.setState({ [field]: value });
  };
  render() {
    const { url } = this.state;

    return (
      <Page
        title="Installation - Wholesale - All in one"
      >
        <Card sectioned>
          <Form onSubmit={this.handleSubmit}>
            <FormLayout>
              <TextField
                autoComplete={false}
                value={url}
                onChange={(v) => { this.setState({url:v}) }}
                label="Shopify base URL"
                type="text"
                fieldID="storeURL"
                placeholder="Enter shopify URL"
                helpText="Example: https://website.myshopify.com"
              />
              {(this.validation() ? null : <InlineError message="Shopify URL is required" fieldID="storeURL" />)}
              <Button
                primary
                submit
                loading={this.state.loading}
                disabled={!this.validation()}
              > Install Application </Button>
            </FormLayout>
          </Form>
        </Card>
      </Page>
    );
  }
}