import React from 'react';
import { Card,Layout,Stack,Heading,Badge,Link } from '@shopify/polaris';
// import { CircleTickOutlineMinor } from '@shopify/polaris-icons';
import GeneralSettings from './GeneralSettings';
import axios from '../Axios';
import SkeletonPageComp from './SkeletonPageComp';
import ToastMessage from '../Common/ToastMessage';

export default class GeneralSettingsDashboard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            selected_id:'general',

            // Fetch in child component "GeneralSettings", saving to eager-load later
            settings:null,

            showToast: false,
            toastMsg: '',
            toastError: false
        }
    }

    /* LifeCycle: Component loaded */
    componentDidMount(){
        axios.get('/get-settings/'+this.props.shop)
        .then(response => {
          if(response.data.length > 0){
              var data = response.data[0];
              var tws = data.table_widget_style;
              var pp_discount_text = [];
              if(data.pp_discount_text && data.pp_discount_text.length > 0){
                pp_discount_text = data.pp_discount_text[0];
              }
              try{
                const state = {
                  page_loaded: true,
                  discount_method_value: data.discount_method,
                  discount_text: data.discount_text,
                  compareAtPrice: data.compare_at_price,
                  ShowAsCompareAtPrice: data.show_compare_at_price,
                  hide_cents: data.hide_cents,
                  sale_clock_type: data.sale_clock.sale_clock_type,
                  sale_clock_text1: data.sale_clock.sale_clock_text1,
                  sale_clock_text2: data.sale_clock.sale_clock_text2,
                  show_saleClock: data.sale_clock.show_sale_clock,
                  bg_color: data.sale_clock.style.bg_color[0],
                  fg_color: data.sale_clock.style.fg_color[0],
                  saleClockFontSize: data.sale_clock.style.font_size,
                  saleclock_text_align: data.sale_clock.style.text_align,
                  hex_bg_color: data.sale_clock.style.hex_bg_color,
                  hex_fg_color: data.sale_clock.style.hex_fg_color,
                  saleClockBorderRadius: data.sale_clock.style.border_radius,
                  enable_app: data.enable_app,
                  default_table_style: tws.length > 0?tws[0].default_table_style:this.state.default_table_style,
                  table_bg_clr: tws.length>0?tws[0].table_bg_clr:this.state.table_bg_clr,
                  table_fg_clr: tws.length>0?tws[0].table_fg_clr:this.state.table_fg_clr,
                  table_hex_bg_clr: tws.length>0?tws[0].table_hex_bg_clr:this.state.table_hex_bg_clr,
                  table_hex_fg_clr: tws.length>0?tws[0].table_hex_fg_clr:this.state.table_hex_fg_clr,
                  table_template_class: tws.length>0?tws[0].table_template_class:this.state.table_template_class,
                  thead_bg_clr: tws.length>0?tws[0].thead_bg_clr:this.state.thead_bg_clr,
                  thead_fg_clr: tws.length>0?tws[0].thead_fg_clr:this.state.thead_fg_clr,
                  thead_hex_bg_clr: tws.length>0?tws[0].thead_hex_bg_clr:this.state.thead_hex_bg_clr,
                  thead_hex_fg_clr: tws.length>0?tws[0].thead_hex_fg_clr:this.state.thead_hex_fg_clr,
                  table_text_align: tws.length>0?tws[0].table_text_align:this.state.table_text_align,
                  table_border_hex_clr: tws.length>0?tws[0].table_border_hex_clr:this.state.table_border_hex_clr,
                  table_border_clr: tws.length>0?tws[0].table_border_clr:this.state.table_border_clr,
                  isSetDiscountMethod: true,
                  ppdt_suffix: pp_discount_text.text?pp_discount_text.text.suffix:this.state.ppdt_suffix,
                  ppdt_prefix: pp_discount_text.text?pp_discount_text.text.prefix: this.state.ppdt_prefix,
                  ppdt_additionalStyle: pp_discount_text.style?pp_discount_text.style.ppdt_additionalStyle: this.state.ppdt_additionalStyle,
                  ppdtFontSize: pp_discount_text.style?pp_discount_text.style.ppdtFontSize: this.state.ppdtFontSize,
                  ppdtTextAlign: pp_discount_text.style?pp_discount_text.style.ppdtTextAlign: this.state.ppdtTextAlign,
                  ppdt_fg_color: pp_discount_text.style?pp_discount_text.style.ppdt_fg_color: this.state.ppdt_fg_color,
                  ppdt_bg_color: pp_discount_text.style?pp_discount_text.style.ppdt_bg_color: this.state.ppdt_bg_color,
                  ppdt_hex_fg_color: pp_discount_text.style?pp_discount_text.style.ppdt_hex_fg_color: this.state.ppdt_hex_fg_color,
                  ppdt_hex_bg_color: pp_discount_text.style?pp_discount_text.style.ppdt_hex_bg_color: this.state.ppdt_hex_bg_color,
                  enable_additional_coupon_code: data.enable_additional_coupon_code?data.enable_additional_coupon_code: this.state.enable_additional_coupon_code,
                  disable_checkout_while_loading: data.disable_checkout_while_loading || false,
                  logincustomization_signup_link_type: data.logincustomization_signup_link_type || "append",
                  logincustomization_signup_link: data.logincustomization_signup_link || "/pages/register",
                  logincustomization_signup_link_label: data.logincustomization_signup_link_label || "Create wholesale account",
                  additionalFee_enabled: data.additionalFee_enabled || false,
                  additionalFee_label: data.additionalFee_label || "Processing Fee",
                  additionalFee_value: data.additionalFee_value || "0",
                  additionalFee_value_type: data.additionalFee_value_type || "fixed",
                  shop_domain: data.shop_domain,
                  additional_coupon_code_tag_based_customer_tags: data.additional_coupon_code_tag_based  ? data.additional_coupon_code_tag_based.tags : [],
                  additional_coupon_code_tag_based_enabled: data.additional_coupon_code_tag_based ? data.additional_coupon_code_tag_based.enabled : false,
                  preventAutomaticDiscounts: data.preventAutomaticDiscounts || false,
                };
                this.setState({settings: state});
  
              }catch(e){
                // console.log("ERROR:",e);
              }
            }else{
              this.setState({isSetDiscountMethod: false});
              this.showToastMessage("Select a discount method.", true);
            }
        })
        .catch(error => {
          this.showToastMessage("Error", true);
        })
    }

    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
      }
      
      dissmissToast = () =>{
        this.setState({
          showToast: false,
        });
    }

    render(){
        // const tabs = [
        //     {
        //       id: 'general',
        //       content: 'General',
        //       url:'#',
        //       icon:CircleTickOutlineMinor,
        //     },
        //     {
        //       id: 'discount_method',
        //       content: 'Discount Method',
        //       url:'#',
        //       icon:CircleTickOutlineMinor,
        //     },
        //     {
        //       id: 'sale_clock',
        //       content: 'Sale Clock',
        //       url:'#',
        //       icon:CircleTickOutlineMinor,
        //     }
        //   ];

          var toast = this.state.showToast? (
            <ToastMessage
            onDismiss={this.dissmissToast}
            message={this.state.toastMsg}
            error={this.state.toastError}
            />
          ): (null);

          return(
            <>
                {this.state.settings ? 
                    <div>
                        <Layout>
                            {/* General Section */}
                            <Layout.Section>
                                <Card title="General" sectioned>
                                        <GeneralSettings 
                                            selected_tab={1} 
                                            shop={this.props.shop} 
                                            shopData={this.props.shopData} 
                                      shopDomain={this.state.settings.shop_domain}
                                      settings={this.state.settings}
                                        />
                                </Card>
                            </Layout.Section>

                            <Layout.Section>
                                    <GeneralSettings 
                                        selected_tab={2} 
                                        shop={this.props.shop} 
                                        shopData={this.props.shopData} 
                                        shopDomain={this.state.settings.shop_domain}
                                        settings={this.state.settings}
                                    />
                            </Layout.Section>

                            {/*  Discount methods  Section */}
                            <Layout.Section>
                            <Card 
                                title={
                                  <Stack spacing="tight" alignment="center">
                                    <Heading>Checkout discount methods</Heading>
                                    <Badge>
                                      <Link plain url="https://support.digitalcoo.com/hc/en-us/articles/360038886091" external>Learn more</Link>
                                    </Badge>
                                  </Stack>
                                }
                                sectioned
                              >
                                <GeneralSettings 
                                  selected_tab={3} 
                                  shop={this.props.shop} 
                                  shopData={this.props.shopData} 
                                  shopDomain={this.state.settings.shop_domain}
                                  settings={this.state.settings}
                                />
                              </Card>
                            </Layout.Section>
                            
                            {/* Login Customization Section */}
                            <Layout.Section>
                                <GeneralSettings 
                                    selected_tab={5} 
                                    shop={this.props.shop} 
                                    shopData={this.props.shopData} 
                                    shopDomain={this.state.settings.shop_domain}
                                    settings={this.state.settings}
                                />
                            </Layout.Section>
                            
                            {/* Additional Fee Section */}
                            <Layout.Section>
                                <GeneralSettings 
                                    selected_tab={6} 
                                    shop={this.props.shop} 
                                    shopData={this.props.shopData} 
                                    shopDomain={this.state.settings.shop_domain}
                                    settings={this.state.settings}
                                />
                            </Layout.Section>

                            {/* Sale Clock Section */}
                            <Layout.Section>
                                <Card title="Sale Clock" sectioned>
                                    <GeneralSettings 
                                        selected_tab={4} 
                                        shop={this.props.shop} 
                                        shopData={this.props.shopData} 
                                        shopDomain={this.state.settings.shop_domain}
                                        settings={this.state.settings}
                                    />
                                </Card>
                            </Layout.Section>
                            
                        </Layout>
                    </div>
                : <SkeletonPageComp />}
                {toast}
            </>
        );
    }
}