import React, { Component } from 'react';
import axios from 'axios';
import { Spinner, Card } from '@shopify/polaris';
import querystring from 'querystring';

const parsed_query = querystring.parse(window.location.search);
export default class ManualDraftOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shop: this.props.shop,
            manual_redirect_message: null,
            msg_for_user: "Applying discount to the draft order. Please wait a moment..."
        };
    }

    fetch_data() {
        if(parsed_query && parsed_query.id){
            axios.get('https://wholesalecheckout.digitalcoo.com/draft-order/236/' + this.props.shop + '/' + parsed_query.id)
            .then(response => {
                this.setState({
                    msg_for_user: "Draft order successfully updated ",
                });
                window.top.location.href = 'https://'+this.props.shop+'/admin/draft_orders/'+parsed_query.id;
            })
            .catch(error => {
                window.top.location.href = 'https://'+this.props.shop+'/admin/draft_orders/'+parsed_query.id;
            });
        }
        else{
            this.setState({
                msg_for_user: "Invalid request",
            })
            window.top.location.href = 'https://'+this.props.shop+'/admin/draft_orders';
        }
    }

    componentDidMount() {
        this.fetch_data();
    }

    render() {
        const {msg_for_user,manual_redirect_message} = this.state;
        const  pageLoading = <div className="snipper-container">
            <center><Spinner color="teal" size="large" /></center>
        </div>;

        return (
            <Card sectioned>
                {pageLoading}
                <div>{msg_for_user}</div>
                {manual_redirect_message}
            </Card>
        );
    }
}