import React from 'react';
import { Card, Layout, DataTable, Icon, Modal, TextField, ButtonGroup, Button, EmptyState, Spinner } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import Switch from "react-switch";
import axios from '../Axios';
import PageTitleBar from '../Common/TitleBar';
import SkeletonPageComp from './SkeletonPageComp';
import ToastMessage from '../Common/ToastMessage';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class ManageLocks extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      rules: [],
      settings:[],
      switch1:false,
      switch2:true,
      switch3:false,
      active:false,
      del_id:'',
      pageCount:20,
      setOption:[],
      inputValue:'',
      page_loaded: false,
      loading: false,
      showToast: false,
      toastError: false,
      toastMsg: ""
    }
  }

  componentDidMount(){
    this.getRules();
  }

  getRules(){
    axios.get("/app/manage-locks?shop="+this.props.shop)
    .then(response => {
      if(response.data.status !== null && typeof response.data.status !== 'undefined' && response.data.status === 0){
        this.setState({page_loaded: true, loading: false});
      }
      else {
        this.setState({rules: response.data,page_loaded: true, loading: false});
      }
    }).catch(error => {
      this.setState({page_loaded: true, loading: false});
    })
  }

  toast(msg,error){
    this.setState({
        showToast: true,
        toastMsg: msg,
        toastError: error
    });
  }

  updateText(value){
    this.setState({inputValue:value});
    if(value === ''){
      this.setState({setOption:this.state.settings});
    }
    else{
      const filterRegex = new RegExp(value, 'i');
      const resultOptions = this.state.settings.filter((option) =>
        option.lock_name.match(filterRegex)
      );
      this.setState({setOption:resultOptions});
    }
  }

  deleteRule(item){
    if(window.confirm("Do you want to delete ?")){
      this.setState({loading: true});
      axios.delete("/app//manage-lock?shop="+this.props.shop+"&id="+item._id+"&keys="+item.meta_keys)
      .then(response => {
        if(response && response.data){
          if(response.data.status===0) this.toast(response.data.message, true);
          else this.toast(response.data.message);
        }

        this.getRules();
      }).catch(error => {
        this.getRules();
      });
    }
  }

  updateItem(item){
    var new_state = item.state === "published"? "unpublished":"published";
    if(window.confirm("Do you want to "+new_state+"?")){
      var data = {
        shop: this.props.shop,
        manage_lock : item.manage_lock,
        state: new_state
      }
      this.setState({loading: true});
      axios.put("/app/manage-lock/"+item._id, data).then(response => {
        this.getRules();
      })
      .catch(error => {
        this.getRules();
      });
    }
  }

  getCustomerTitle(manage_lock){
    var txt = "";
    switch (manage_lock.applies_to) {
      case "selected_customers":
        txt = "Customer With Tag";
        break;
      case "loggedin_customers":
        txt = "Customer With An Account";
        break;
      case "all_customers":
        txt = "All Visitors";
        break;
      default:
        break;
    }
    return txt;
  }

  getLockTypeTitle(manage_lock){
    var txt = "";
    if(manage_lock.access_permissions === "access"){
      txt = "Can Access: ";
    }
    else{
      txt = "Can Not Access: ";
    }
    if(manage_lock.content_name === "products"){
      txt += "Products";
    }
    else if(manage_lock.content_name === "collections"){
      txt += "Collections";
    }
    else if(manage_lock.content_name === "entire_store"){
      txt += "Entire Store";
    }
    else if(manage_lock.content_name === "pages"){
      txt += "Pages";
    }
    else if(manage_lock.content_name === "url"){
      txt += "URLs";
    }
    return txt;
  }

  render(){
    const rows = this.state.rules.map( item => [
     <span className="text-capitalize">{item.manage_lock.title || "NA"}</span>,
      <span className="text-capitalize">{this.getCustomerTitle(item.manage_lock)}</span>,
      <span className="text-capitalize">{this.getLockTypeTitle(item.manage_lock)}</span>,
      <label><Switch onChange={()=>{ this.updateItem(item);}}
      checked={item.state === "published"} /></label>,
      <ButtonGroup>
        <Button primary url={BUILD_VERSION_EXTENSION+'/manage-locks/edit/'+item._id} target="APP">Edit</Button>
        <Button destructive 
          onClick={()=>{
            this.deleteRule(item);
          }}>
          Delete
        </Button>
      </ButtonGroup>
    ]);


    var toast = this.state.showToast? (<ToastMessage message={this.state.toastMsg} error={this.state.toastError} onDismiss={()=>{this.setState({showToast: false})}} />):(null);


    var popup_modal = <div>
      <Modal
        open={this.state.active}
        onClose={()=> {this.setState({active:false})}}
        title="Wholesale Lock Manager - Delete Lock"
        primaryAction={{
          content: 'Ok',
          onAction: ()=>{console.log(this.state.del_id)},
        }}
        secondaryActions={[
            {
                content: 'Cancel',
                onAction: ()=>{this.setState({active:false})},
            },
        ]}
      >
        <Modal.Section>
            <p>
                Are you sure you want to remove this lock ?
            </p>
        </Modal.Section>
      </Modal>
    </div>;

    const lock_lists = <Card sectioned>
      <DataTable
          columnContentTypes={[
          'text',
          'text',
          'text',
          'bool',
          'text',
          ]}
          headings={[
          <strong>Lock Name</strong>,
          <strong>Visitor/Customer</strong>,
          <strong>Lock Type</strong>,
          <strong>Lock Status</strong>,
          <strong>Actions</strong>,
          ]}
          rows={rows}
      />
    </Card>;

    const empty_state = <div>
      <EmptyState
        heading="Manage Your Wholesale Store"
        action={{content: 'Create Lock',url:BUILD_VERSION_EXTENSION+"/manage-locks/create", target:"APP"}}
        image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
      >
      <p>
      Manage accessibility level for the products, collections or entire store
      </p>
    </EmptyState>
    </div>;
    if(this.state.loading){
      return(
        <div>
          <div className="snipper-container">
            <Spinner color="teal" size="large"/>
          </div>
          {toast}
        </div>
      );
    }
    else{
      if(this.state.page_loaded){
        if(this.state.rules && this.state.rules.length){
          return(
            <div className="LockMain">
                <PageTitleBar
                  title="Manage Lock addon"
                  breadcrumbs={[
                    { content: 'Dashboard', url: BUILD_VERSION_EXTENSION+'/dashboard', target: 'APP' }
                  ]}
                  primaryAction={{
                    content: "Create Lock",
                    url: BUILD_VERSION_EXTENSION+"/manage-locks/create",
                    target: "APP"
                  }}
                />
                <Layout>
                    <Layout.Section>
                      {
                        rows.length>=10 &&
                        <TextField
                            onChange={(value)=>this.updateText(value)}
                            label="search"
                            labelHidden
                            value={this.state.inputValue}
                            prefix={<Icon source={SearchMinor} color="inkLighter" />}
                            placeholder="Search"
                        />
                      }
                      {lock_lists}
                    </Layout.Section>
                </Layout>
                {popup_modal}
                {toast}
            </div>
          );
        }
        else{
          return(
          <>
            <PageTitleBar
              title="Manage Lock"
              breadcrumbs={[
                { content: 'Dashboard', url: BUILD_VERSION_EXTENSION+'/dashboard', target: 'APP' }
              ]}
              primaryAction={{
                content: "Create Lock",
                url: BUILD_VERSION_EXTENSION+"/manage-locks/create",
                target: "APP"
              }}
            />
          {empty_state}
          </>);
        }
      }
      else{
        return(
          <SkeletonPageComp></SkeletonPageComp>
        );
      }
    }
  }
}

