import  React, { Component } from 'react';
import {Card, Layout, Stack, Button, ButtonGroup, Tag, Checkbox, Select, TextField, FormLayout, List, Banner, Icon, Link, Heading} from '@shopify/polaris';
import axios from '../Axios';
import ResourcePicker from '../Common/ResourcePicker';
import ToastMessage from '../Common/ToastMessage';
import PageTitleBar from '../Common/TitleBar';
import {DragHandleMinor} from '@shopify/polaris-icons';
const BUILD_VERSION_EXTENSION =  process.env.REACT_APP_BUILD_VERSION_EXTENSION;

export default class CreateQuickOrderForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            show: this.props.show,
            shop: this.props.shop,
            products: [],
            collections: [],
            addToCartType: "multiple",
            addToCartButtonLabel: "Add to cart",
            updateCartButtonLabel: "Update cart",
            displayOnPage: "",
            enableQuickView: true,
            title: "",
            state: "published",
            open_products_picker: false,
            open_collections_picker: false,
            edit_rule_id: this.props.match.params.id,
            errors: [],
            pagination: false,
            numOfProductsPerPage: 10,
            showToast: false,
            toastMsg: '',
            toastError: false,
            draggedIndex: null
        }
    }

    componentDidMount(){
        if(this.props.show === "edit" && this.state.edit_rule_id){
            this.getRuleForEdit();
        }
    }

    showToastMessage(msg, error){
        this.setState({
          showToast: true,
          toastMsg: msg,
          toastError: error||false,
        });
    }
    
    dissmissToast = () =>{
        this.setState({
          showToast: false,
        });
    }

    hasFeatures(value){
        var hasF = false;
        if(this.props.shopData && this.props.shopData.shop_features && this.props.shopData.shop_features.length > 0){
            hasF = this.props.shopData.shop_features.findIndex( x => x === value ) > -1;
        }
        return hasF;
    }

    getRuleForEdit(){
        axios.get('/app/quick-order-form/' + this.state.edit_rule_id +"/"+ this.props.shop)
        .then(response => {
            if(response && response.data && response.data.quickOrderForm){
                var {
                    shop,products,collections,addToCartType,addToCartButtonLabel,updateCartButtonLabel,displayOnPage,enableQuickView,title,state,pagination,numOfProductsPerPage,
                } = response.data.quickOrderForm;
                this.setState({
                    shop,products,collections,addToCartType,addToCartButtonLabel,updateCartButtonLabel,displayOnPage,enableQuickView,title,state,pagination,numOfProductsPerPage,
                });
            }
            else{
                this.showToastMessage("Rule does not exists", true);
                this.props.history.push(BUILD_VERSION_EXTENSION+'/quick-order-forms');
            }
        })
        .catch(function (error) {
            this.showToastMessage("Server error", true);
            this.props.history.push(BUILD_VERSION_EXTENSION+'/quick-order-forms');
        })
    }
    handleDragStart = (index) => {
        this.setState({ draggedIndex: index });
    };

    handleDragOverForProducts = (index) => {
        const { draggedIndex, products } = this.state;
        if (index !== draggedIndex) {
            const newProductsList = [...products];
            const draggedTag = newProductsList[draggedIndex];
            newProductsList.splice(draggedIndex, 1);
            newProductsList.splice(index, 0, draggedTag);
            this.setState({ products: newProductsList, draggedIndex: index });
        }
    };

    handleDragOverForCollections = (index) => {
        const { draggedIndex, collections } = this.state;
        if (index !== draggedIndex) {
            const newCollectionsList = [...collections];
            const draggedTag = newCollectionsList[draggedIndex];
            newCollectionsList.splice(draggedIndex, 1);
            newCollectionsList.splice(index, 0, draggedTag);
            this.setState({ collections: newCollectionsList, draggedIndex: index });
        }
    };

    handleDragEnd = () => {
        this.setState({ draggedIndex: null });
    };
    renderProductsLikeTags(){
        return this.state.products.map((product,index) => {
            return <div
                    key={index}
                    style={{margin:"4px", cursor: "grab"}}
                    className="tag"
                    draggable
                    onDragStart={() => this.handleDragStart(index)}
                    onDragOver={(e) => { e.preventDefault(); this.handleDragOverForProducts(index); }}
                    onDragEnd={this.handleDragEnd}
                >
                    <Tag
                        onRemove={this.removeTag.bind(this,product,index)}
                    ><div style={{display: "flex", gap: '5px'}}><Icon source={DragHandleMinor} tone="base" /> <span style={{borderLeft: '1px solid #b3b3b3'}}></span> <span>{product.title}</span></div></Tag>
                </div>
        });
    }
    renderCollectionsLikeTags(){
        return this.state.collections.map((collection,index) => {
            return <div
                    key={index}
                    style={{margin:"4px", cursor: "grab"}}
                    className="tag"
                    draggable
                    onDragStart={() => this.handleDragStart(index)}
                    onDragOver={(e) => { e.preventDefault(); this.handleDragOverForCollections(index); }}
                    onDragEnd={this.handleDragEnd}
                >
                    <Tag
                        onRemove={this.removeTag2.bind(this,collection,index)}
                    ><div style={{display: "flex", gap: '5px'}}><Icon source={DragHandleMinor} tone="base" /> <span style={{borderLeft: '1px solid #b3b3b3'}}></span> <span>{collection.title}</span></div></Tag>
                </div>
        });
    }
    removeTag(product,i,el){
        var products = this.state.products;
        products.splice([i],1);
        this.setState({ products });
    }
    removeTag2(collection,i,el){
        var collections = this.state.collections;
        collections.splice([i],1);
        this.setState({ collections });
    }
    validation(){
        var valid = true;
        var errors = [];
        if(!this.hasFeatures("quick_order_form")){
            this.showToastMessage("Please subscribe to this addon to use it", true);
            valid = false;
            return valid;
        }
        if( (1>this.state.products.length) && (1>this.state.collections.length) ){
            errors.push("Select products or collection");
            valid=false;
        }
        if(null===this.state.displayOnPage || this.state.displayOnPage.trim() === ""){
            errors.push("Display on page link can not be empty");
            valid=false;
        }
        else{
            if(this.state.displayOnPage.indexOf("pages/") === -1){
                // url must contains pages/
                errors.push("Display on page link must contains valid URL format i.e. pages/quick-order-form");
                valid=false;
            }
        }
        if(null===this.state.addToCartButtonLabel || this.state.addToCartButtonLabel.trim() === ""){
            errors.push("Add to cart label can not be blank");
            valid=false;
        }
        if(null===this.state.updateCartButtonLabel || this.state.updateCartButtonLabel.trim() === ""){
            errors.push("Update cart label can not be blank");
            valid=false;
        }
        if(this.state.pagination){
            try{
                if(!(this.state.numOfProductsPerPage) || this.state.numOfProductsPerPage.trim() === ''){
                    errors.push("'Number of Products Per Page' field can not be blank");
                    valid=false;
                }
            }catch(e){}
        }
        this.setState({errors:errors});
        return valid;
    }
    saveRule(){
        if(!(this.validation())){
            var errorCount = this.state.errors.length;
            var mesg = `There ${(1===errorCount?"is ":"are ")} ${errorCount} ${(1===errorCount?"error":"errors")}.`;
            this.showToastMessage(mesg, true);
            window.scrollTo({top: 0, behavior: 'smooth'});
            return false;
        }
        var data = {
            shop: this.props.shop,
            products: this.state.products,
            collections: this.state.collections,
            addToCartType: this.state.addToCartType,
            addToCartButtonLabel: this.state.addToCartButtonLabel,
            updateCartButtonLabel: this.state.updateCartButtonLabel,
            displayOnPage: this.state.displayOnPage,
            enableQuickView: this.state.enableQuickView,
            title: this.state.title,
            state: this.state.state,
            pagination: this.state.pagination,
            numOfProductsPerPage: this.state.numOfProductsPerPage
        };
        if(this.props.show === "edit" && this.state.edit_rule_id){
            data.id = this.state.edit_rule_id;
            axios.put('/app/quick-order-form/update', data)
            .then(response => {
                if (response.data.status === 1) {
                    this.showToastMessage(response.data.message);
                    this.props.history.push(BUILD_VERSION_EXTENSION+'/quick-order-forms');
                } else {
                    this.showToastMessage(response.data.message, true);
                    return false;
                }
            })
            .catch(error => {
                this.showToastMessage("Server error", true);
            });
        }
        else{
            axios.post('/app/quick-order-form/create', data)
            .then(response => {
                if (response.data.status === 1) {
                    this.showToastMessage(response.data.message);
                    this.props.history.push(BUILD_VERSION_EXTENSION+'/quick-order-forms');
                } else {
                    this.showToastMessage(response.data.message, true);
                    return false;
                }
            })
            .catch(error => {
                this.showToastMessage("Server error", true);
            });
        }
    }

    render(){
        const {addToCartType,updateCartButtonLabel,addToCartButtonLabel,enableQuickView,title,displayOnPage,errors,pagination,numOfProductsPerPage} = this.state;
        const section_products_section = <div>
            {
                this.state.open_products_picker && 
                <ResourcePicker
                    onSelection={(resources) => {
                        var products = this.state.products || [];
                        if(resources.selection && resources.selection.length > 0){
                            resources.selection.forEach( item => {
                                if (products.findIndex(x=>x.id === item.id) === -1) {
                                    products.push({
                                        id: item.id,
                                        title: item.title,
                                        handle: item.handle,
                                        image: item.image?item.image:{}
                                    });
                                }
                            });
                        }
                        this.setState({
                            products: products,
                            open_products_picker: false
                        });
                    }}
                    onCancel={() => this.setState({ open_products_picker: false })}
                />
            }
            <Stack vertical>
                <ButtonGroup>
                    <Button onClick={() => this.setState({open_products_picker: true})}> Select products </Button>
                    {/* {
                        0<this.state.products.length && (
                            <Button>
                                Edit ( <label style={{ borderBottom: '1px dotted' }}>{this.state.products.length} {this.state.products.length===1?"product":"products"}</label> )
                            </Button>
                        )
                    } */}
                </ButtonGroup>
                {
                    0<this.state.products.length && 
                    (
                        <div>
                            <Stack alignment="baseline">
                                {this.renderProductsLikeTags()}
                            </Stack>
                        </div>
                    )
                }
            </Stack>
        </div>;
        const section_collection_section = <div>
            {
                this.state.open_collections_picker && 
                <ResourcePicker
                    resourceType="Collection"
                    onSelection={(resources) => {
                        var collections = this.state.collections || [];
                        if(resources.selection && resources.selection.length){
                            resources.selection.forEach(item => {
                                if (collections.findIndex(x=>x.id === item.id) === -1) {
                                    collections.push({
                                        id: item.id,
                                        title: item.title,
                                        handle: item.handle,
                                        image: item.image?item.image:{},
                                    });
                                }
                            });
                        }
                        this.setState({
                            collections: collections,
                            open_collections_picker: false
                        });
                    }}
                onCancel={() => this.setState({ open_collections_picker: false })}
            />
            }
            <Stack vertical>
                <ButtonGroup>
                    <Button onClick={() => this.setState({open_collections_picker: true})}> Select collections </Button>
                    {/* {
                        0<this.state.collections.length && (
                            <Button
                            >
                                Edit ( <label style={{ borderBottom: '1px dotted' }}>{this.state.collections.length} {this.state.collections.length===1?"collection":"collections"}</label> )
                            </Button>
                        )
                    } */}
                </ButtonGroup>
                {
                    0<this.state.collections.length && (
                        <div>
                            <Stack alignment="baseline">
                                {this.renderCollectionsLikeTags()}
                            </Stack>
                        </div>
                    )
                }
            </Stack>
        </div>;
        var validation_errors = <div><Banner status="critical" title={<span>There {errors.length > 1 ? "are" : "is"} {errors.length} {errors.length > 1 ? "errors" : "error"} in quick order form</span>}>
            <List type="number">
                {
                    this.state.errors.map((error, key) => {
                        return <List.Item key={key}>{error}</List.Item>;
                    })
                }
            </List>
        </Banner>
        <div style={{margin: "2rem 0"}}></div>
        </div>;

        
        var toast = this.state.showToast? (
            <ToastMessage
              onDismiss={this.dissmissToast}
              message={this.state.toastMsg}
              error={this.state.toastError}
            />
          ): (null);


        return (
            <div>
                <PageTitleBar
                    title={this.props.show === "add"?"Add":"Edit"}
                    breadcrumbs={[{content: 'Quick order form', url: BUILD_VERSION_EXTENSION+'/quick-order-forms', target: 'APP'}]}
                    secondaryActions={[{content: 'Back', url: BUILD_VERSION_EXTENSION+'/quick-order-forms', target: 'APP'}]}
                    primaryAction={{content:"Save", onAction: () => this.saveRule(), disabled: !this.hasFeatures("quick_order_form")} }
                />
                {toast}
                <Layout>
                    <Layout.Section>
                        {
                            (0<errors.length) && validation_errors
                        }
                        <Card title="Title"
                            actions={[
                                {
                                    content: "Quick Order Form Tutorial",
                                    url: "https://digitalcoo.zendesk.com/hc/en-us/articles/360036190012",
                                    external: true,
                                    target: "new"
                                }
                            ]}
                        >
                            <Card.Section>
                                <Stack vertical>
                                    <TextField
                                        autoFocus
                                        label="Title"
                                        placeholder="Title"
                                        labelHidden
                                        value={title}
                                        onChange={(title) => {this.setState({title})}}
                                    />
                                    <TextField
                                        label="Display on page"
                                        value={displayOnPage}
                                        helpText="For example: pages/quick-order-form"
                                        prefix={`https://${this.props.shop} /`}
                                        onChange={(displayOnPage) => {this.setState({displayOnPage})}}
                                    />
                                </Stack>
                            </Card.Section>
                        </Card>
                        <Card title={
                            <Stack distribution="equalSpacing">
                                <Heading>Products selections</Heading>
                                <Link external url="https://support.digitalcoo.com/hc/en-us/articles/30058975874836" >How to sort?</Link>
                            </Stack>
                        }>
                             <Card.Section>{section_collection_section}</Card.Section>
                             <Card.Section>{section_products_section}</Card.Section>
                        </Card>
                        <div style={{marginBottom: "2rem"}}></div>
                        <Card title="Settings">
                            <Card.Section>
                                <Stack vertical>
                                    <Checkbox
                                        label="Enable quick view"
                                        checked={enableQuickView}
                                        onChange={(enableQuickView)=>{this.setState({enableQuickView})}}
                                    />
                                    <Checkbox
                                        label="Show pagination"
                                        checked={pagination}
                                        onChange={(pagination) => {this.setState({pagination})}}
                                    />
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <Select
                                                label="Add to cart type"
                                                id="Add to cart type"
                                                options={[
                                                    {label: "Single", value: "single"},
                                                    {label: "Multiple", value: "multiple"},
                                                ]}
                                                onChange={(a,b) => {
                                                    this.setState({addToCartType:a})
                                                }}
                                                value={addToCartType}
                                            />
                                            {
                                                "single" === addToCartType && 
                                                (<TextField
                                                    label="Add to cart label"
                                                    value={addToCartButtonLabel}
                                                    id="add-to-cart-label"
                                                    onChange={(a)=>{this.setState({addToCartButtonLabel:a})}}
                                                />)
                                            }
                                            {
                                                "single" !== addToCartType && 
                                                (<TextField
                                                    label="Update cart label"
                                                    value={updateCartButtonLabel}
                                                    id="update-cart-label"
                                                    onChange={(a)=>{this.setState({updateCartButtonLabel:a})}}
                                                />)
                                            }
                                        </FormLayout.Group>
                                            {
                                                pagination &&
                                                (
                                                    <FormLayout.Group>
                                                        <TextField
                                                            label="Numbers of products per page"
                                                            value={numOfProductsPerPage}
                                                            id="numbers-of-products-per-page"
                                                            onChange={(v) => {this.setState({numOfProductsPerPage:v})}}
                                                        />
                                                        <div></div>
                                                    </FormLayout.Group>
                                                )
                                            }
                                    </FormLayout>
                                </Stack>
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                </Layout>
            </div>
        );
    }

}